//global color palette

$title-jungle-green: #46c7c0;
$title-whale-blue: #82b6fc;
$title-honeydrew-orange: #ff9b52;
$paragraph-ghoul-grey: #5f7294;
$info-wisp-grey: #707070;
$error-tomato-red: #ff5252;
$background-snow-white: #f2f5f7;
$background-snowing-white: #e7effa;
$border-owl-white: #cbdcf2;
$bar-clay-green: #5bc0a6;
$input-midnight-blue: #102243;

$side-bar-text: #252629;
$dashboard-text: #252629;
$positive-green: #3a6351;
$out-red: #632626;
$verify-green: #65886c;
$tomato-red: #7a2f2f;
$royal-blue: #4f8bbe;

// system colors
$system-dark-blue: #1365ac;
$system-link-blue: #0775d1;
$system-active-blue: #40daff;
$system-error-red: #fa3232;
$system-title-black: #25334a;
$system-text-grey: #526586;
$system-light-green: #1bbf8d;
$system-dark-green: #0ca777;
