@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.so-org-details-container {
  display: flex;

  .so-org-details-white-container {
    margin-top: 2rem;
    margin-left: 3rem;

    .so-org-details-back-container {
      display: flex;
      flex-direction: row;

      .so-nav-left-chevron {
        width: 1rem;
        height: 1rem;
        margin-top: 0.25rem;
      }
      .so-nav-back-text {
        color: $so-color-nav-back-text;
        opacity: 1;
        font: $so-font-nav-back-text;
        margin-left: 0.5rem;
      }
    }

    .so-org-details-subtitle {
      text-align: left;
      font: normal normal bold 1rem/1.5625rem Open Sans;
      letter-spacing: 0rem;
      color: #25334a;
      opacity: 1;
      margin-top: 1rem;
    }

    .so-org-title-icon-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .so-org-details-title {
        font: $so-font-create-single-entity-title-label;
        letter-spacing: 0rem;
        color: $so-color-family-title;
        opacity: 1;
        margin-top: 0.625rem;
        margin-bottom: 2rem;
      }

      .so-org-details-img {
        width: 4.125rem;
        height: 3.125rem;
      }
    }

    .so-org-details-form-inputs {
      .so-org-details-info-container {
        .so-org-details-form-title {
          font: $so-font-sidebar-name-label;
          letter-spacing: 0rem;
          color: $so-color-family-title;
          opacity: 1;
        }
        .so-org-details-input {
          margin-top: 1rem;
          width: 28.75rem;
          height: 3.75rem;
          padding-left: 1.5rem;
          font: $so-font-input-floating;
          background: #f4f8fa 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e4e5e8;
          border-radius: 0.125rem;
          opacity: 1;
        }
        .so-settings-label {
          font: $so-font-dropdown-floating-label;
          letter-spacing: 0rem;
          color: $so-color-input-floating-label;
          opacity: 1;
          opacity: 1;
          position: absolute;
          pointer-events: none;
          left: 0.75rem;
          top: 0.0625rem;
          transition: 0.2s ease all;
          padding-left: 0.75rem;
          padding-top: 1.125rem;
          padding-bottom: 1.25rem;
        }
      }
    }
    .so-org-details-rect-box {
      width: 61.125rem;
      height: 8rem;
      background: transparent linear-gradient(180deg, #ffffff 0%, #f7fafd 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.1875rem 0.375rem #0000000d;
      border: 0.0625rem solid #e4e5e8;
      opacity: 1;

      .so-org-details-text-container {
        padding-left: 8.75rem;
        padding-right: 8.75rem;
        padding-top: 2rem;
        justify-content: space-around;
        display: flex;
        flex-direction: row;

        .so-org-details-label-data {
          text-align: center;
          display: flex;
          flex-direction: column;
          .so-org-details-data {
            font: normal normal bold 1.625rem/2.25rem Open Sans;
            letter-spacing: 0rem;
            color: #25334a;
            opacity: 1;
          }

          .so-org-details-label {
            font: normal normal medium 1.125rem/1.5rem Open Sans;
            letter-spacing: 0rem;
            color: #526586;
            opacity: 1;
          }
        }
      }
    }
  }
}
