@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.system-owner-login-container {
  font-family: "Open Sans";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .so-rectangle-container {
    display: contents;
    .so-rectangle {
      position: absolute;
      background-position: center;
      /* Layout Properties */
      top: 5.8125rem;
      width: 36.875rem;
      height: 34.1875rem;
      /* UI Properties */
      background: var(---ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.1875rem 0.375rem #00000029;
      border: 0.0625rem solid #eaeaea;
      border-radius: 0.125rem;
      opacity: 1;

      .so-login-container {
        padding-top: 4rem;

        .so-login-title {
          font: $so-font-family-title;
          text-align: left;
          padding-left: 3.5rem;
          margin-bottom: 2.75rem;
          color: $so-color-family-title;
        }

        .so-form-inputs {
          margin-left: 3.625rem;
          margin-right: 4rem;
        }

        .so-email-input {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e4e5e8;
          opacity: 1;
          border-radius: 0.125rem;
          padding-left: 1.5rem;
          font: $so-font-input-floating;
          color: $so-color-input-floating;
        }
        .so-email-label {
          font: $so-font-input-floating-label;
          color: $so-color-input-floating-label;
          padding-left: 1.5rem;
          margin-top: 0.125rem;
        }

        .so-password-input {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e4e5e8;
          opacity: 1;
          border-radius: 0.125rem;
          padding-left: 1.5rem;
          font: $so-font-input-floating;
          color: $so-color-input-floating;
        }
        .so-password-label {
          font: $so-font-input-floating-label;
          color: $so-color-input-floating-label;
          padding-left: 1.5rem;
          margin-top: 0.125rem;
        }

        .so-forget-pw-link {
          margin-top: 2.5rem;
          padding-left: 3.6875rem;
          text-align: left;
          text-decoration: underline;
          font: $so-font-hyperlink;
          color: $so-color-hyperlink;
          opacity: 1;
        }

        .so-field-validation {
          margin-left: 3.625rem;
          text-align: left;
          font: $so-font-field-validation-error;
          letter-spacing: 0rem;
          color: $so-color-field-validation-error;
          opacity: 1;
        }

        .so-login-btn {
          margin-left: 12.6875rem;
          margin-right: 12.4375rem;
          margin-top: 4rem;
          width: 11.75rem;
          height: 3.75rem;
          background: $so-background-color-login-btn;
          border: 0.0625rem solid #1365ac;
          border-radius: 0.125rem;
          opacity: 1;
          color: $so-color-btn;
          font: $so-font-btn;
        }
      }
    }
  }
}
