@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-detected-devices-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9fafc 0% 0% no-repeat padding-box;
  margin: 0 auto;

  .st-detected-devices-contents {
    width: 100%;
    padding: 2.5rem;
    background: #f9fafc 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #e3eaff;
    border-top-left-radius: 0.5625rem;
    border-top-right-radius: 0.5625rem;
    box-shadow: 0rem 0rem 0.375rem rgb(0 0 0 / 14%);

    .st-detected-devices-icon {
      width: 5.125rem;
      height: auto;
    }

    .st-mic-test-recording-title {
      margin-top: 1.25rem;
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      color: $title-whale-blue;
    }

    .st-mic-test-recording-subtitle-mic {
      margin-top: 1rem;
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .st-mic-test-recording-subtitle-speaker {
      margin-top: 1rem;
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .st-detected-devices-title {
      margin-top: 1.25rem;
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      color: $title-whale-blue;
    }

    .st-detected-devices-subtitle {
      margin-top: 1.75rem;
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .css-13cymwt-control,
    .st-detected-devices-dropdown-microphone {
      width: 30.6875rem;
      height: 4.5rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
      border: 0.0625rem solid #7f93b22c;
      border-radius: 2.25rem;
      padding: 1.5rem 2rem;
    }

    .css-1ey381m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
      border: 0.0125rem solid #7F93B22B;
      box-shadow: initial;
      padding-left: 1rem;
      color: $paragraph-ghoul-grey;


    }

  }



  .st-detected-devices-rectangle-container-start-recording {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 2.5rem;
  }
}


/*mic dropdown css*/

#menu- ul li {
  color: $paragraph-ghoul-grey;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: 3px 3px 3px #00000014 !important;
  border: 1px solid #7F93B22C;
  border-radius: 1rem !important;
}