@import "../../../styles/globalSCSS/colors";

.st-mic-test-recording-rectangle-container-start-recording {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.1875rem 0.375rem #00000012;
  border: 0.0625rem solid #e3eaff;
  border-radius: 0.5625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 2.5rem;

  .st-mic-test-recording-start-recording-label {
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
    padding-bottom: 0.75rem;

    .st-mic-test-recording-duration {
      margin-left: 4.0625rem;
      color: "$title-whale-blue";
    }
  }

  .st-mic-test-recording-hear-verification-container {
    position: relative;
    width: 100%;

    .hear-label {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
      margin-top: 2rem;
    }

    .hear-verification-btn-set {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
      margin-top: 1rem;

      .st-mic-test-hear-no-try-again-icon {
        width: 12.375rem;
        height: 4.6875rem;
      }

      .st-mic-test-hear-yes-icon {
        width: 12.375rem;
        height: 4.6875rem;
      }
    }
  }

  .st-mic-test-recording-audio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    margin-top: 0.75rem;

    .st-mic-test-recording-audio-bar {
      .audio-large {
        transform-origin: top left;
      }
    }

    .st-mic-test-record-mic-icon {
      width: 4.125rem;
      height: auto;
    }

    .st-mic-test-stop-record-mic-icon {
      width: 4.125rem;
      height: auto;
    }

    .st-mic-test-re-record-mic-icon {
      width: 4.125rem;
      height: auto;
    }
  }

  .hear-verification-btn-set {
    .studentbtn-cta {
      width: 12.5rem;
    }
  }
}

.st-test-your-mic-verify-btn:hover,
.st-detected-devices-record-mic-icon:hover,
.st-mic-test-re-record-mic-icon:hover,
.st-mic-test-hear-no-try-again-icon:hover,
.st-mic-test-hear-yes-icon:hover,
.st-detected-devices-close-btn:hover {
  cursor: pointer;
}

.st-detected-devices-rectangle-container-start-recording {
  width: 68.75rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0.1875rem 0.375rem 0.375rem rgba(0, 0, 0, 0.0705882353);
  border: 0.0625rem solid #e3eaff;
  border-radius: 0.5625rem;
  margin-top: -0.625rem;
  padding: 1.5rem 0;

  .st-detected-devices-start-recording-label {
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
  }

  .st-detected-devices-record-mic-icon {
    width: 4.125rem;
    height: 4.5625rem;
    margin-top: 0.3125rem;
  }

  .st-info-instructions-lets-start-btn-container {
    margin-top: 1.75rem;
  }
}