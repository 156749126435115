.go2072408551 {
    max-width: initial !important;
    padding: 1rem 2rem;
    font-family: IBM Plex Serif !important;
}

.go2072408551 button {
    color: #7cbffd !important;
    padding: 1rem 2rem !important;
    border: 0 !important;
    border-radius: 32px;
    font: normal normal 600 1.25rem / 1.875rem IBM Plex Serif !important;
    font-weight: 500 !important;
    background-color: #F5F7FA !important;
}

.go2072408551 span {
    margin: 0px !important;
}