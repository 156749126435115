@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-block-mic-container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0rem 0.1875rem 0.375rem #00000012;
  border: 0.0625rem solid #e3eaff;
  border-radius: 0.5625rem;
  margin: 0 auto;

  .st-block-mic-contents {
    padding: 2.5rem;

    .st-block-mic-icon {
      width: 5.125rem;
      height: auto;
    }

    .st-block-mic-title {
      margin-top: 2.25rem;
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      color: $title-honeydrew-orange;
    }

    .st-block-mic-subtitle {
      margin-top: 1.5rem;
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .st-block-mic-verify-btn {
      margin-top: 4.25rem;
    }
  }
}