//global typography font styles

$font-global-family: "IBM Plex Serif", serif;
$font-title: 1.375rem;
$font-subtitle: 1.25rem;
$font-icon-desc: 1.125rem;
$font-card-or-labels: 1rem;

$font-weight-bold: bold;
$font-weight-800: 800;
$font-weight-subtitle: 600;

//Student

//System Owner (SO)

$so-font-family-title: normal normal bold 2.25rem/3.0625rem Open Sans;
$so-font-family-subtitle: normal normal medium 1rem/1.375rem Open Sans;
$so-font-input-floating: normal normal 600 1rem/1.25rem Open Sans;
$so-font-input-floating-label: normal normal 600 1rem/1.25rem Open Sans;
$so-font-dropdown-floating-label: normal normal 600 0.875rem/1.1875rem Open Sans;
$so-font-hyperlink: normal normal 600 1rem/1.375rem Open Sans;
$so-font-btn: normal normal 600 1.125rem/1.5rem Open Sans;
$so-font-field-validation-error: normal normal 600 1rem/1.375rem Open Sans;

$so-font-sidebar-name-label: normal normal bold 1.25rem/1.6875rem Open Sans;
$so-font-main-dashboard-initial-title-label: normal normal bold 1.625rem/2.25rem
  Open Sans;
$so-font-sidebar-nav-item-label: normal normal bold 1rem/1.5625rem Open Sans;
$so-font-nav-back-text: normal normal 600 1.125rem/1.5rem Open Sans;
$so-font-create-single-entity-title-label: normal normal bold 1.625rem/2.25rem
  Open Sans;
$so-font-create-admin-note: normal normal 600 0.875rem/1.5625rem Open Sans;

$so-font-settings-title: normal normal bold 1.625rem/2.25rem Open Sans;

$so-color-nav-back-text: #2c97f4;
$so-color-sidebar-name-label: #ffffff;
$so-color-main-dashboard-initial-title-label: #25334a;
$so-color-main-dashboard-initial-title-label-blue: #2c97f4;
$so-color-sidebar-nav-item-label-selected: #40daff;
$so-color-sidebar-nav-item-label-not-selected: #ffffff;
$so-color-family-title: #25334a;
$so-color-family-subtitle: #526586;
$so-color-input-floating: #526586;
$so-color-input-floating-label: #526586;
$so-color-hyperlink: #0775d1;
$so-color-btn: #ffffff;
$so-color-back-btn: #1365ac;
$so-color-field-validation-error: #fa3232;

$so-background-color-login-btn: #1365ac 0% 0% no-repeat padding-box;
$so-background-color-back-btn: #ffffff 0% 0% no-repeat padding-box;
