@import "../../../../styles/globalSCSS/colors";
@import "../../../../styles/globalSCSS/typography";

.so-sch-main-dashboard-container {
  display: flex;

  .so-sch-main-dashboard-white-container {
    margin-top: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    width: 100%;
    justify-content: center;

    .so-sch-main-dashboard-searchbar-container {
      margin-top: 1rem;

      height: 3.75rem;
      margin-bottom: 1rem;

      .so-sch-main-dashboard-merge {
        display: flex;
        flex-direction: row;
        margin-left: 0.25rem;
        justify-content: space-around;

        .so-sch-main-dashboard-input {
          width: 100%;
          height: 3.75rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0rem 0.1875rem 0.375rem #0000000d;
          border: 0.0625rem solid #e4e5e8;
          border-radius: 0.125rem;
          opacity: 1;
          outline: none;
          padding-left: 1.5625rem;
          padding-right: 1.5625rem;

          font: normal normal medium 1rem/1.25rem Open Sans;
          letter-spacing: 0rem;
          color: #526586;
          opacity: 1;
        }

        .so-sch-main-dashboard-search-icon-box {
          width: 4.75rem;
          height: 3.625rem;
          background: #f2f4f5 0% 0% no-repeat padding-box;
          opacity: 1;
          box-shadow: 0rem 0.1875rem 0.375rem #0000000d;
          border: 0.0625rem solid #e4e5e8;
          border-radius: 0.125rem;

          .so-sch-main-dashboard-icon {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .so-sch-main-dashboard-pagination-container {
      margin-top: 1.0625rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .so-sch-main-dashboard-table-pagination {
        text-align: left;
        font: normal normal 600 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #25334a;
        opacity: 1;
      }

      .so-sch-main-dashboard-table-count {
        text-align: left;
        font: normal normal 600 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #25334a;
        opacity: 1;
      }

      .Mui-selected {
        background-color: #2c97f4;
        color: #ffffff;
      }

      .MuiTablePagination-toolbar {
        align-items: baseline;
        padding-left: 0.3125rem;
      }
    }
    .so-sch-main-dashboard-table-container {
      width: 99.5%;
      margin-left: 0.25rem;

      .so-sch-main-dashboard-link {
        text-align: left;
        text-decoration: underline;
        font: normal normal 600 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #2c97f4;
        opacity: 1;
      }
      .so-sch-main-dashboard-table-row {
        width: 61.25rem;
        height: 3.75rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #e4e5e8;
        border-radius: 0.125rem;
        opacity: 1;

        .so-sch-main-dashboard-table-cell {
          text-align: left;
          font: normal normal 600 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #25334a;
          opacity: 1;
        }
      }

      .so-sch-main-dashboard-table-head-container {
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #f7fafd 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0rem 0.1875rem 0.375rem #0000000d;
        border: 0.0625rem solid #e4e5e8;
        border-radius: 0.125rem;
        opacity: 1;

        .so-sch-main-dashboard-table-head-labels {
          font: normal normal bold 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #25334a;
          opacity: 1;
        }
      }
    }

    .so-sch-main-dashboard-title-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .so-sch-main-dashboard-title {
        font: $so-font-main-dashboard-initial-title-label;
        letter-spacing: 0rem;
        color: $so-color-main-dashboard-initial-title-label;
        opacity: 1;
        margin-right: 37.6875rem;
      }

      .so-sch-main-dashboard-btn {
        width: 11.75rem;
        height: 3.75rem;
        background: #1365ac 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #1365ac;
        border-radius: 0.125rem;
        text-align: left;
        font: normal normal 600 1.125rem/1.5rem Open Sans;
        letter-spacing: 0rem;
        color: #ffffff;
        opacity: 1;
        padding-left: 1rem;
      }
      .so-sch-main-dashboard-btn-menu {
        margin-top: 0.25rem;
        width: 11.75rem;

        .so-sch-main-dashboard-btn-menu-item {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          text-align: left;
          font: normal normal 600 1.125rem/1.5rem Open Sans;
          letter-spacing: 0rem;
          opacity: 1;
          padding-left: 1rem;
        }
      }
    }
  }
}
