@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.system-owner-forget-pw-container {
  font-family: "Open Sans";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.so-forget-pw-rectangle-container {
  display: contents;
  .so-forget-pw-rectangle {
    position: absolute;
    background-position: center;
    /* Layout Properties */
    top: 5.25rem;
    width: 36.75rem;
    height: 30.75rem;
    /* UI Properties */
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border: 0.0625rem solid #eaeaea;
    border-radius: 0.125rem;
    opacity: 1;

    .so-forget-pw-login-container {
      padding-top: 4rem;

      .so-forget-pw-login-title {
        font: $so-font-family-title;
        color: $so-color-family-title;
        text-align: left;
        padding-left: 4rem;
        margin-bottom: 2rem;
      }

      .so-forget-pw-login-subtitle {
        font: $so-font-family-subtitle;
        color: $so-color-family-subtitle;
        text-align: left;
        letter-spacing: 0rem;
        opacity: 1;
        margin-left: 4rem;
        font-weight: 600;
      }

      .so-forget-pw-form-inputs {
        margin-left: 3.625rem;
        margin-right: 4rem;
      }

      .so-forget-pw-email-input {
        margin-top: 2rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #e4e5e8;
        opacity: 1;
        border-radius: 0.125rem;
        padding-left: 1.5rem;
        font: $so-font-input-floating;
        color: $so-color-input-floating;
      }

      .so-forget-pw-email-label {
        color: $so-color-input-floating-label;
        font: $so-font-input-floating-label;
        padding-left: 1.5rem;
        top: 0.0625rem;
      }

      .so-forget-pw-field-validation {
        margin-left: 3.625rem;
        text-align: left;
        font: $so-font-field-validation-error;
        letter-spacing: 0rem;
        color: $so-color-field-validation-error;
        opacity: 1;
      }

      .so-forget-pw-btn-container {
        display: flex;
        flex-direction: row;
        margin-top: 4.25rem;
        justify-content: center;

        .so-forget-pw-btn-send {
          width: 11.75rem;
          height: 3.75rem;
          background: #1365ac 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #1365ac;
          border-radius: 0.125rem;
          opacity: 1;
          color: #ffffff;
          font-size: 1.125rem;
          font-weight: 600;
          font: $so-font-btn;
          color: $so-color-btn;
        }

        .so-forget-pw-btn-back {
          margin-right: 1.25rem;
          width: 11.75rem;
          height: 3.75rem;
          border: 0.0625rem solid var(---1365ac);
          background: $so-background-color-back-btn;
          color: $so-color-back-btn;
          border: 0.0625rem solid #1365ac;
          border-radius: 0.125rem;
          opacity: 1;
          font: $so-font-btn;
        }
      }
    }
  }
}
