@import "../../../styles/globalSCSS/colors";

.student-home-cards-rect {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000015;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student-home-image {
  width: 100%;
  height: max-content;
}

.home-card-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  font: normal normal 600 1rem/1.75rem IBM Plex Serif;
  letter-spacing: 0rem;
  color: $paragraph-ghoul-grey;
  margin-top: 1.5rem;
}

.home-card-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  font: normal normal 700 1rem/1.75rem IBM Plex Serif;
  letter-spacing: 0rem;
  color: $paragraph-ghoul-grey;
  margin-top: 0.25rem;
  text-transform: lowercase;

  .home-card-status-data-open {
    color: $title-whale-blue;
  }

  .home-card-status-data-incomplete {
    color: $error-tomato-red;
  }

  .home-card-status-data-completed {
    color: $title-jungle-green;
  }

  div:nth-child(2)::first-letter {
    text-transform: uppercase;
  }
}

.student-home-card-btn {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.debug {
  display: none;
}

@media (max-width: 768px) {
  .student-home-cards-rect {
    width: 100%;
  }

  .student-home-cards-container {
    justify-content: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .student-home-cards-rect {
    width: calc(50% - 1.25rem);
  }
}

@media (min-width: 1025px) {
  .student-home-cards-rect {
    width: calc(33.33% - 1.67rem);
    max-width: 21.688rem;
  }
}
