@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.so-crte-admin-init-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .so-crte-admin-init-sidebar-components-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .so-admin-init-white-container {
      margin-top: 2rem;
      margin-left: 3rem;

      .so-admin-init-title {
        font: $so-font-main-dashboard-initial-title-label;
        letter-spacing: 0rem;
        color: $so-color-main-dashboard-initial-title-label;
        opacity: 1;
      }

      .so-admin-white-rec-container {
        width: 61.5625rem;
        height: 29.3125rem;
        margin-top: 2rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0rem 0.1875rem 0.5rem #b4b4b45a;
        border: 0.0625rem solid #e4e5e884;
        border-radius: 0.125rem;
        opacity: 1;

        .so-admin-acc {
          width: 9.9375rem;
          height: 9.9375rem;
          margin-left: 25.875rem;
          margin-top: 3rem;
        }

        .so-admin-acc-building-title-container {
          text-align: center;
          display: flex;
          flex-direction: row;
          letter-spacing: 0rem;
          opacity: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 1.5rem;

          .so-admin-acc-building-title-black {
            font: $so-font-main-dashboard-initial-title-label;
            letter-spacing: 0rem;
            color: $so-color-main-dashboard-initial-title-label;
            margin-right: 0.4375rem;
          }

          .so-admin-acc-building-title-blue {
            font: $so-font-main-dashboard-initial-title-label;
            letter-spacing: 0rem;
            color: $so-color-main-dashboard-initial-title-label-blue;
          }
        }

        .so-admin-acc-building-subtitle {
          text-align: left;
          font: $so-font-family-subtitle;
          letter-spacing: 0rem;
          color: $so-color-family-subtitle;
          opacity: 1;
          margin-top: 1.5rem;
          margin-left: 14.9375rem;
        }

        .so-admin-acc-building-btn-ctn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 2.5rem;

          .admin-acc-btn-single {
            width: 11.75rem;
            height: 3.75rem;
            background: #1365ac 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #1365ac;
            border-radius: 0.125rem;
            opacity: 1;
            text-align: left;
            font: $so-font-btn;
            letter-spacing: 0rem;
            color: $so-color-btn;
            opacity: 1;
            padding-left: 2.3125rem;
            margin-right: 1rem;
          }

          .admin-acc-btn-multiple {
            width: 11.75rem;
            height: 3.75rem;
            background: #1365ac 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #1365ac;
            border-radius: 0.125rem;
            opacity: 1;
            text-align: left;
            font: $so-font-btn;
            letter-spacing: 0rem;
            color: $so-color-btn;
            opacity: 1;
            padding-left: 1.625rem;
          }
        }
      }
    }
  }
}
