@import "../../../styles/globalSCSS/colors";

.refresh-modal {
  .refresh-modal-container {
    background: white;
    padding: 4rem;
    border-radius: 2rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
    max-width: 44.563rem;
    position: relative;

    .refresh-modal-close-btn {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
    }

    h1 {
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-honeydrew-orange;
      margin-top: 1rem;
    }

    span {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $paragraph-ghoul-grey;
    }

    img.no-btn {
      margin-right: 1rem;
    }

    .modal-cta img:hover,
    .refresh-modal-close-btn:hover {
      cursor: pointer;
    }
  }

  .refresh-modal-message {
    font: normal normal bold 1.5rem/2rem IBM Plex Serif;
    color: #333;
  }

  .refresh-second-modal-message {
    margin-bottom: 2rem;
  }

  .popup-actions {
    .studentbtn-cta {
      margin: 0 auto;
      margin-top: 2rem;
    }
  }
}