@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.system-owner-password-changed-container {
  font-family: "Open Sans";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .so-pw-changed-rectangle-container {
    display: contents;
    position: relative;

    .so-pw-changed-rectangle {
      position: absolute;
      background-position: center;
      /* Layout Properties */
      top: 8.1875rem;
      top: 5.25rem;
      width: 31.25rem;
      height: 25.375rem;
      /* UI Properties */
      background: var(---ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.1875rem 0.375rem #00000029;
      border: 0.0625rem solid #eaeaea;
      border-radius: 0.125rem;
      opacity: 1;

      .so-pw-changed-label {
        font: normal normal bold 2.25rem/3.0625rem Open Sans;
        letter-spacing: 0rem;
        color: #25334a;
        opacity: 1;
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .so-pw-changed-sub-label {
        font: normal normal medium 1rem/1.5625rem Open Sans;
        letter-spacing: 0rem;
        color: #25334a;
        opacity: 1;
        text-align: center;
      }
    }
  }
}
