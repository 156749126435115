@import "../../../styles/globalSCSS/colors";

.incomplete-exercise-modal {
  .incomplete-exercise-modal-container {
    background: white;
    padding: 4rem;
    border-radius: 2rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
    max-width: 40.625rem;
    position: relative;

    .incomplete-exercise-popup-close-btn {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
    }

    h1 {
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-honeydrew-orange;
      padding-bottom: 1rem;
      margin-top: 1rem;
    }

    p {
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: 0px;
      color: $paragraph-ghoul-grey;
    }

    img.no-btn {
      margin-right: 1rem;
    }

    .modal-cta img:hover,
    .incomplete-exercise-popup-close-btn:hover {
      cursor: pointer;
    }
  }

  .incomplete-exercise-popup-message {
    font: normal normal bold 1.25rem;
    color: #333;
    margin-bottom: 1.5rem;
  }

  .modal-cta {
    .studentbtn-cta {
      margin: 0 0.5rem;
      margin-top: 1rem;
    }
  }
}