.studentbtn-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.625rem;
  height: 4.3125rem;
  background: transparent linear-gradient(176deg, #86d1ff 0%, #71acfc 100%) 0% 0% no-repeat padding-box;
  border: 0.25rem solid #ffffff;
  border-radius: 2.5625rem;
  box-shadow: 0 0.375rem 0 0 #e5e6e8;
  color: #ffffff;
  font: normal normal bold 1.375rem/1.8125rem IBM Plex Serif;
  cursor: pointer;

  &--primary {
    background: transparent linear-gradient(176deg, #86d1ff 0%, #71acfc 100%) 0% 0% no-repeat padding-box;
  }

  &--secondary {
    background: transparent linear-gradient(176deg, #F5F7FA 0%, #EDF2FA 100%) 0% 0% no-repeat padding-box;
    color: #71acfc;
  }

  &--inactive {
    background: #e5e6e8 0% 0% no-repeat padding-box;
    cursor: none;
    pointer-events: none;
  }
}