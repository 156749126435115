@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.so-crte-org-container {
  display: flex;

  .so-org-white-container {
    margin-top: 2rem;
    margin-left: 3rem;

    .create-org-back-container {
      display: flex;
      flex-direction: row;

      .so-nav-left-chevron {
        width: 1rem;
        height: 1rem;
        margin-top: 0.25rem;
      }
      .so-nav-back-text {
        color: $so-color-nav-back-text;
        opacity: 1;
        font: $so-font-nav-back-text;
        margin-left: 0.5rem;
      }
    }

    .so-create-org-title {
      font: $so-font-create-single-entity-title-label;
      letter-spacing: 0rem;
      color: $so-color-family-title;
      opacity: 1;
      margin-top: 0.125rem;
    }

    .so-create-org-details {
      font: $so-font-sidebar-nav-item-label;
      letter-spacing: 0rem;
      color: $so-color-family-title;
      opacity: 1;
      margin-top: 1.5rem;
    }

    .so-create-org-form-inputs {
      .so-floating-dropdown-container {
        margin-top: 1rem;
      }
      .so-select {
        width: 28.75rem;
        height: 3.75rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 0.125rem;
        opacity: 1;
      }

      .ant-input {
      }
      .ant-select-selection-item {
        font: $so-font-input-floating;
        color: $so-color-input-floating;
        padding-left: 0.875rem;
        padding-top: 1.625rem;
      }
      .ant-select .ant-select-selector {
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 3.75rem;
      }

      .so-create-org-name-input {
        margin-top: 1rem;
        width: 28.75rem;
        height: 3.75rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #e4e5e8;
        border-radius: 0.125rem;
        opacity: 1;
        padding-left: 1.5rem;
        font: $so-font-input-floating;
        color: $so-color-input-floating;
      }
      .so-create-org-name-label {
        font: $so-font-input-floating-label;
        letter-spacing: 0rem;
        color: $so-color-input-floating-label;
        opacity: 1;
        position: absolute;
        pointer-events: none;
        left: 0.75rem;
        top: 0.0625rem;
        transition: 0.2s ease all;
        padding-left: 0.75rem;
        padding-top: 1.125rem;
        padding-bottom: 1.25rem;
      }
    }
  }
}
