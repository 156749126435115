*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

html {
  font-size: 16px;
  line-height: 1.5;
}

body {
  font-size: 1rem;
  line-height: 1.5;
}

ul,
ol {
  list-style: none;
}

q,
blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

:focus {
  outline: none;
}

input,
button,
select,
textarea {
  font: inherit;
  border: none;
  outline: none;
  background: none;
}

a {
  text-decoration: none;
}

@media (max-width: 480px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1025px) {
  html {
    font-size: 16px;
  }
}
