@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.student-history-container {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  width: 1100px;

  .student-card-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.5rem;
  }

  .student-history-filter-container {
    display: flex;
    flex-direction: row;
    margin-top: 1.5625rem;
    opacity: 1;
    margin-bottom: 1.6875rem;
    justify-content: space-between;

    .student-history-title {
      font: normal normal bold 1.375rem/1.8125rem IBM Plex Serif;
      letter-spacing: 0rem;
      color: $paragraph-ghoul-grey;
      opacity: 1;
      margin-top: 0.3125rem;
    }

    .student-history-filter-btn-container {
      display: flex;
      flex-direction: row;

      >div {
        margin-right: 1rem;
      }

      >div:last-child {
        margin-right: 0;
      }

      .student-history-selected-button {
        width: 8.625rem;
        height: 3.25rem;
        background: #e7effa 0% 0% no-repeat padding-box;
        border: 0.125rem solid #cbdcf2;
        opacity: 1;
        border-radius: 3.125rem;

        .student-history-label {
          text-align: center;
          margin-top: 0.625rem;
          font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
          letter-spacing: 0rem;
          color: $paragraph-ghoul-grey;
          opacity: 1;
        }
      }

      .student-history-unselected-button {
        width: 8.625rem;
        height: 3.25rem;
        border: 0.125rem solid #dfe7f0a5;
        opacity: 1;
        border-radius: 3.125rem;
        margin-right: 1rem;

        :hover {
          cursor: pointer;
        }

        .student-history-label {
          text-align: center;
          margin-top: 0.625rem;
          font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
          letter-spacing: 0rem;
          color: $paragraph-ghoul-grey;
          opacity: 1;
        }
      }

      .student-history-unselected-button:nth-child(3) {
        margin-right: 0;
      }
    }
  }

  .student-history-card-rows {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: center;
    gap: 0 2.5rem;

    .student-history-cards-container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      text-align: center;
      gap: 0 2.5rem;

      .student-history-cards-rect {
        width: 21rem;
        height: 24.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0rem 0.1875rem 0.375rem #00000015;
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .student-history-image {
          width: 21rem;
        }

        .history-card-label-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          font: normal normal 600 1rem/1.75rem IBM Plex Serif;
          letter-spacing: 0rem;
          color: $paragraph-ghoul-grey;
          opacity: 1;
          margin-top: 1.5rem;
        }

        .history-card-data-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          font: normal normal 600 1rem/1.75rem IBM Plex Serif;
          letter-spacing: 0rem;
          color: $paragraph-ghoul-grey;
          opacity: 1;
          margin-top: 0.25rem;

          .history-card-status-data-completed {
            color: $title-jungle-green;
          }

          .history-card-status-data-incomplete {
            color: $error-tomato-red;
          }
        }

        .student-history-card-btn {
          margin-top: 1rem;
        }
      }
    }
  }
}