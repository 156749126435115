@import "../../styles/globalSCSS/colors";

/* Old Design*/
/*
.audio-bar-basic {
  border-radius: 2.3125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  height: auto;

  &.with-text {
    padding-left: 1rem;
    padding-right: 2.5rem;
  }

  .audio-bar-icon {
    width: 3.6875rem;
    height: 3.6875rem;
    margin-right: 1rem;
  }

  .audio-bar-sounds {
    flex: 1;
    display: flex;
    align-items: center;

    p.ai-prompt-text {
      text-align: left;
      font: normal normal bold 1.5rem/2rem IBM Plex Serif;
      letter-spacing: 0;
      color: #ffffff;
      margin: 0;
    }
  }
}

.audio-bar-basic.green {
  background: $title-jungle-green 0% 0% no-repeat padding-box;
  min-height: 4.6875rem;
}

.audio-bar-basic.white {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 3.75rem;

  .audio-bar-icon {
    width: 1.5rem;
    height: auto;
  }
}
*/

/*Confirmed Design CSS*/

.audio-bar-basic {
  border-radius: 2.3125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  height: auto;

  &.with-text {
    padding-left: 1rem;
    padding-right: 2.5rem;
  }

  .audio-bar-icon {
    width: 3.6875rem;
    height: 3.6875rem;
    margin-right: 1rem;
  }

  .audio-bar-sounds {
    flex: 1;
    display: flex;
    align-items: center;

    p.ai-prompt-text {
      text-align: left;
      font: normal normal bold 1.5rem/2rem IBM Plex Serif;
      letter-spacing: 0;
      color: #ffffff;
      margin: 0;
    }
  }
}

.audio-bar-basic.green {
  background: $title-jungle-green 0% 0% no-repeat padding-box;
  min-height: 4.6875rem;
}

.audio-bar-basic.white {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 3.75rem;

  .audio-bar-icon {
    width: 1.5rem;
    height: auto;
  }
}

.student-information-container .st-mic-test-recording-audio-container canvas.audio-visualizer-canvas {
  width: 220px;
  margin-right: 83px;
}

.st-mic-test-recording-rectangle-container-start-recording .audio-bar-basic {
  padding: 0 1.5rem 0 1rem !important;
}

@media screen and (max-width: 1020px) {
  .audio-bar-basic {
    min-height: 4.75rem !important;
  }
}