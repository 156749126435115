.so-modal-container {
  display: flex;

  .so-modal-overlay {
    background-color: rgba(31, 31, 31, 0.59);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .so-modal-main-container {
    margin-top: 3.75rem;
    margin-left: 18.4375rem;
    width: 49.375rem;
    height: 35.125rem;
    /* UI Properties */
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.125rem;
    opacity: 1;

    .so-modal-close-icon {
      width: 3.4375rem;
      height: 3.4375rem;
      margin-left: 45.5rem;
    }
    .so-modal-label-upload-container {
      margin-top: 0.5rem;
      margin-left: 4.125rem;
      display: block;
    }
    .so-modal-title {
      text-align: left;
      font: normal normal bold 1.625rem/2.25rem Open Sans;
      letter-spacing: 0rem;
      color: #1365ac;
      opacity: 1;
    }

    .so-modal-subtitle {
      margin-top: 1.75rem;
      font: normal normal 500 1rem/1.375rem Open Sans;
      letter-spacing: 0rem;
      color: #526586;
      .so-subtitle-highlight {
        font: normal normal 500 1rem/1.375rem Open Sans;
        color: #2c97f4;
      }
    }

    .so-subtitle-rectangle {
      margin-top: 1.5rem;
      width: 41.125rem;
      height: 14.375rem;
      /* UI Properties */
      border: 0.0625rem dashed var(---526586);
      background: #f4f8fa 0% 0% no-repeat padding-box;
      border: 0.0625rem dashed #526586;
      opacity: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .loader-container {
        display: contents;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        width: 2.9375rem;
        height: 2.9375rem;

        .loader-upload-label {
          margin-top: 1rem;
          font: normal normal medium 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #526586;
          opacity: 1;
        }

        .loader-file-label {
          margin-top: 0.5rem;
          font: normal normal medium 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #526586;
          opacity: 1;
        }
      }

      .so-modal-upload-icon {
        margin-bottom: 1.25rem;
      }

      .so-modal-upload-label {
        margin-top: 0.5rem;
        font: normal normal 500 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #526586;
        opacity: 1;
        text-align: center;
      }

      .so-modal-browse {
        margin-top: 0.5rem;
        text-decoration: underline;
        font: normal normal 600 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #2c97f4;
        opacity: 1;
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  .so-modal-error-main-container {
    margin-top: 3.75rem;
    margin-left: 18.4375rem;
    width: 49.375rem;
    height: 39rem;

    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.125rem;
    opacity: 1;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    .so-modal-close-icon {
      width: 3.4375rem;
      height: 3.4375rem;
      margin-left: 45.5rem;
    }
    .so-modal-label-upload-container {
      margin-top: 0.5rem;
      margin-left: 4.125rem;
      display: block;
    }
    .so-modal-title {
      text-align: left;
      font: normal normal bold 1.625rem/2.25rem Open Sans;
      letter-spacing: 0rem;
      color: #1365ac;
      opacity: 1;
    }

    .so-modal-subtitle {
      margin-top: 1.75rem;
      font: normal normal 500 1rem/1.375rem Open Sans;
      letter-spacing: 0rem;
      color: #526586;
      .so-subtitle-highlight {
        font: normal normal 500 1rem/1.375rem Open Sans;
        color: #2c97f4;
      }
    }

    .so-subtitle-rectangle {
      margin-top: 1.5rem;
      width: 41.125rem;
      height: 14.375rem;
      /* UI Properties */
      border: 0.0625rem dashed var(---526586);
      background: #f4f8fa 0% 0% no-repeat padding-box;
      border: 0.0625rem dashed #526586;
      opacity: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .loader-container {
        display: contents;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        width: 2.9375rem;
        height: 2.9375rem;

        .loader-upload-label {
          margin-top: 1rem;
          font: normal normal medium 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #526586;
          opacity: 1;
        }

        .loader-file-label {
          margin-top: 0.5rem;
          font: normal normal medium 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #526586;
          opacity: 1;
        }
      }

      .so-modal-upload-icon {
        margin-bottom: 1.25rem;
      }

      .so-modal-upload-label {
        margin-top: 0.5rem;
        font: normal normal 500 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #526586;
        opacity: 1;
        text-align: center;
      }

      .so-modal-browse {
        margin-top: 0.5rem;
        text-decoration: underline;
        font: normal normal 600 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #2c97f4;
        opacity: 1;
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }

    .so-error-msg-container {
      margin-top: 1rem;
      background: #f05959 0% 0% no-repeat padding-box;
      border-radius: 0.125rem;
      opacity: 1;
      display: flex;
      flex-direction: row;
      width: 41.125rem;
      height: 3.1875rem;
      justify-content: center;
      align-items: center;

      .so-error-msg-icon {
        width: 1.375rem;
        height: 1.375rem;
      }
      .so-error-msg-label {
        text-align: left;
        font: normal normal 600 1rem/1.3125rem Open Sans;
        letter-spacing: 0rem;
        color: #ffffff;
        opacity: 1;
        margin-left: 0.75rem;
      }
    }
  }

  .so-modal-btn-container {
    display: flex;
    justify-content: center;

    .so-modal-button {
      width: 11.75rem;
      height: 3.75rem;
      /* UI Properties */

      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.0625rem solid #1365ac;
      border-radius: 0.125rem;
      opacity: 1;

      font: normal normal 600 1.125rem/1.5rem Open Sans;
      letter-spacing: 0rem;
      color: #1365ac;
      margin-top: 1.75rem;
    }
  }
}
