@import "../../styles/globalSCSS/colors";
@import "../../styles/globalSCSS/typography";

.nav-bar-home-logout-container {
  display: flex;
  justify-content: center;
  height: 4rem;
  flex-direction: row;
  align-items: center;
  background: transparent linear-gradient(180deg, #6cc9d5 0%, #2faaa5 100%) 0%
    0% no-repeat padding-box;
  font-family: $font-global-family;
  padding: 1.125rem 0;

  .nav-bar-home-logout-icon-set {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;

    .nav-bar-home-logout-text-label {
      font-size: $font-title;
      font-weight: $font-weight-bold;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .nav-bar-home-widget {
    display: flex;
    justify-content: space-between;
  }
}

.nav-bar-home-logout-icon-set:hover {
  cursor: pointer !important;
}

.home-icon {
  width: 1.8125rem;
  height: auto;
}

.logout-icon {
  width: 1.375rem;
  height: auto;
}
