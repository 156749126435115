@import "../../../src/styles/globalSCSS/colors";
@import "../../../src/styles/globalSCSS/typography";

.basic {
  padding: 1rem 2rem;
  font-size: $font-icon-desc;
  border: none;
  color: #fff;
  margin: 1rem;
  outline: none;
  border-radius: 20px;
  min-width: 250px;
  height: 50px;
}

.login-btn {
  padding: 1rem 2rem;
  font-size: $font-card-or-labels;
  font-weight: $font-weight-subtitle;
  color: $paragraph-ghoul-grey;
  font-family: "Open Sans", sans-serif;
  border: none;
  margin: 1rem;
  outline: none;
  border-radius: 5px;
  min-width: 180px;
  height: 60px;
}

.so-create-org-btn {
  margin-top: 110px;
  font: $so-font-btn;
  background: #1365ac 0% 0% no-repeat padding-box;
  color: $so-color-btn;
  border: 1px solid #1365ac;
  border-radius: 2px;
  opacity: 1;
  width: 188px;
  height: 60px;
}

.so-create-admin-btn {
  font: $so-font-btn;
  background: #1365ac 0% 0% no-repeat padding-box;
  color: $so-color-btn;
  border: 1px solid #1365ac;
  border-radius: 2px;
  min-width: 180px;
  height: 60px;
  margin-top: 50px;
}
