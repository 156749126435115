@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.modal-inactive-mic-overlay {
  .modal-inactive-mic-container {
    position: relative;
    max-width: 44.0625rem;
    height: max-content;
    background-position: center;
    padding: 4rem;
    background-color: #fff;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border: 0.0625rem solid #eaeaea;
    border-radius: 2rem;

    .modal-inactive-mic-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 2.9375rem;
      aspect-ratio: 1;
      border: none;
      border-radius: 100%;
      margin: 1rem;
      background: var(--unnamed-color-e3eaff) 0% 0% no-repeat padding-box;
      background: #e3eaff 0% 0% no-repeat padding-box;
      cursor: pointer;
    }

    .modal-inactive-mic-img {
      margin-bottom: 0.75rem;
    }

    .modal-inactive-mic-title {
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      color: $title-honeydrew-orange;
      margin-bottom: 2rem;
    }

    .modal-inactive-mic-desc {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
      margin-bottom: 4rem;
    }

    .modal-inactive-mic-cta {
      display: flex;
      justify-content: center;
    }
  }
}
