@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";
@import "../../../styles/globalSCSS/margins";

.st-login-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../../assets/studentLoginBackground.png);
  background-size: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  &__disabled {
    pointer-events: none;
    opacity: 0.35;
  }

  .st-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .st-rectangle {
      background: #ffffff;
      width: 100%;
      max-width: 39rem;
      padding: 3rem 4.25rem;
      box-shadow: 0rem 0.1875rem 0.375rem #00000029;
      border: 0.0625rem solid #eaeaea;
      border-radius: 1.5rem;
    }

    .st-login-contents-container {
      .st-login-subtitle {
        margin-top: 1rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-jungle-green;
      }

      .st-login-email-label {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-login-email-label-validation {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-login-email-input-error-highlight {
        margin-top: 0.75rem;
        width: 100%;
        height: 4.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $input-midnight-blue;
        padding: 1.5rem 2rem;
        background: #ffffff;
        box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
        border: 0.0625rem solid $error-tomato-red;
        border-radius: 2.25rem;
      }

      .st-login-email-input-validation {
        margin-top: 0.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $error-tomato-red;
      }

      .st-login-pin-label {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-login-pin-label-validation {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-login-pin-container {
        display: flex;
        justify-content: space-between;

        .st-login-circular-pin {
          width: 4.5rem;
          height: 4.5rem;
          background: #ffffff;
          box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
          border: 0.0625rem solid #7f93b22c;
          border-radius: 2.25rem;
          font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
          color: $input-midnight-blue;
          text-align: center;
          padding: 0;
        }

        .st-login-circular-pin-validation {
          width: 4.5rem;
          height: 4.5rem;
          background: #ffffff;
          box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
          border: 0.0625rem solid $error-tomato-red;
          border-radius: 2.25rem;
          font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
          color: $input-midnight-blue;
          text-align: center;
        }
      }

      .st-login-forgot-pin-label {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;

        .st-login-forget-pin-link {
          text-decoration: underline;
          color: $title-jungle-green;
        }
      }

      .st-login-forgot-pin-label-validation {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;

        .st-login-forget-pin-link {
          text-decoration: underline;
          color: $title-jungle-green;
        }
      }

      .st-login-pin-validation {
        margin-top: 0.75rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $error-tomato-red;
      }

      .st-login-error-message {
        margin-top: 0.75rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $error-tomato-red;
      }

      .st-login-btn {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;

        img {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .st-login-screen {
    .st-login-container {
      width: 90%;
      height: max-content;

      .st-rectangle {
        padding: 2rem;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .st-login-container {
    width: 90%;
    height: max-content;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {}

@media (min-width: 1025px) {}