@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.student-well-done-modal {
  .student-well-done-rec-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029;
    border-radius: 0.25rem;
    opacity: 1;
    padding: 4rem;
    width: max-content;
    max-width: 37.25rem;
    text-align: center;
    border-radius: 2rem;

    .student-well-done-party-cracker {
      width: 8.438rem;
      height: auto;
      margin: 0 auto;
    }

    .student-well-done-title {
      margin-top: 0.75rem;
      font: normal normal bold 38px/49px IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-jungle-green;
    }

    .student-well-done-subtitle {
      margin-top: 32px;
      font: normal normal 600 20px/30px IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .student-redirect-subtitle {
      margin-top: 0.625rem;
      font: normal normal 600 15px/20px IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }
  }
}