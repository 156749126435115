@import "../../styles/globalSCSS/colors";
@import "../../styles/globalSCSS/typography";

.nav-bar-full-container {
  background: transparent linear-gradient(180deg, #6cc9d5 0%, #2faaa5 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  font-family: $font-global-family;

  .nav-bar-icon-sets {
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-bar-menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
  }

  .hamburger-icon {
    font-size: 2rem;
  }

  .nav-bar-full-three-icon-sets {
    display: flex;

    .nav-bar-full-icon-set {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
      padding: 0.92rem 2rem;
      transition: background-color 0.3s ease;

      img {
        width: 1.8125rem;
        height: 1.625rem;
        margin-right: 0.5rem;
      }

      span {
        font-size: $font-title;
        font-weight: $font-weight-bold;
      }

      &.nav-bar-full-active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .nav-bar-full-icon-set {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    padding: 0.75rem 2rem;
    transition: background-color 0.3s ease;
    cursor: pointer;

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    span {
      font-size: $font-title;
      font-weight: $font-weight-bold;
    }

    &.nav-bar-full-active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  @media (max-width: 765px) {
    .nav-bar-full-icon-set {
      padding: 0.75rem 0rem;
    }

    .nav-bar-menu-toggle {
      display: block;
    }

    .nav-bar-full-three-icon-sets {
      display: none;
    }

    .nav-bar-mobile-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: #2faaa5;
      padding: 2rem 0;
      z-index: 999;
      overflow-y: auto;
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;

      .nav-bar-full-icon-set {
        width: 100%;
        padding: 3rem;
        color: white;
      }

      .nav-bar-full-icon-set img {
        width: 2rem;
        height: 2rem;
        margin-right: 2rem;
      }

      .nav-bar-full-icon-set span {
        font-size: 1.5rem;
        color: white;
      }

      .nav-bar-mobile-menu-close {
        position: absolute;
        margin: 2rem;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
}
