@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-dashboard-container {
  width: 100%;
  height: max-content;
  min-height: 100vh;
  background: #f9fafc;
  padding: 1.563rem 0;
}

.all-exercises-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.student-dashboard-container {
  .student-dashboard-filter-container {
    display: flex;
    flex-direction: row;
    opacity: 1;
    margin-bottom: 1.6875rem;
    justify-content: space-between;

    .student-dashboard-title {
      font: normal normal bold 1.375rem/1.8125rem IBM Plex Serif;
      letter-spacing: 0rem;
      color: $paragraph-ghoul-grey;
      opacity: 1;
      margin-top: 0.3125rem;
      display: flex;
      align-items: center;
    }

    .student-filter-btn-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .student-dashboard-selected-button {
        width: 8.625rem;
        height: 3.25rem;
        background: #e7effa 0% 0% no-repeat padding-box;
        border: 0.125rem solid #cbdcf2;
        border-radius: 3.125rem;

        .student-dashboard-label {
          text-align: center;
          font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
          letter-spacing: 0rem;
          color: $paragraph-ghoul-grey;
          opacity: 1;
        }
      }

      >div {

        display: flex;
        align-items: center;
        justify-content: center;

      }

      .student-dashboard-unselected-button {
        width: 8.625rem;
        height: 3.25rem;
        border: 0.125rem solid #dfe7f0a5;
        border-radius: 3.125rem;
        cursor: pointer;

        :hover {
          cursor: pointer;
        }

        .student-dashboard-label {
          text-align: center;
          font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
          color: $paragraph-ghoul-grey;
        }
      }
    }
  }
}

.student-card-noexercises {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  height: 100%;
  width: 100%;
  margin-top: 8.75rem;

  img {
    width: 8.875rem;
    height: auto;
  }
}

@media (max-width: 480px) {
  .student-dashboard-container {
    .student-dashboard-filter-container {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .student-dashboard-container {
    .student-dashboard-filter-container {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {}

@media (min-width: 1025px) {}