@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-detected-devices-overlay {
  .st-detected-devices-panel {
    position: relative;
    max-width: 44.625rem;
    height: max-content;
    display: block;
    position: fixed;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
    border: 0.0625rem solid #707070;
    border-radius: 2rem;

    .st-detected-devices-close-btn {
      position: absolute;
      margin: 1rem;
      right: 0;
    }

    .st-detected-devices-instructions-desc-container {
      padding: 4rem;

      .st-detected-devices-instructions-title {
        font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
        color: $title-jungle-green;
        padding-bottom: 0.5rem;
      }

      .st-detected-devices-instructions-icon-set {
        margin-top: 1.2rem;
        display: flex;
        flex-direction: row;

        .st-detected-devices-instructions-icon {
          margin-right: 1rem;
          width: 3.5rem;
        }

        .st-detected-devices-instructions-label-set {
          display: flex;
          flex-direction: column;

          .st-detected-devices-instructions-icon-title {
            margin-top: 0.3125rem;
            font: normal normal bold 1.375rem/1.8125rem IBM Plex Serif;
            color: $paragraph-ghoul-grey;
          }

          .st-detected-devices-instructions-icon-subtitle {
            font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
            color: $paragraph-ghoul-grey;
          }
        }
      }
    }

    .st-info-instructions-lets-start-btn-container {
      margin-top: 1.25rem;

      div {
        margin: 0 auto;
      }
    }
  }
}