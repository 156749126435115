.wrapper p {
  color: rgb(192, 192, 192);
  font-family: "Open Sans";
  margin-left:24px;
  margin-bottom:2px !important;
  text-align: left;
font: normal normal 600 14px/25px Open Sans;
letter-spacing: 0px;
color: #526586;
opacity: 1;

}

.title{
  font-family: "Open Sans";
margin-left:24px;
margin-top:12px;
margin-bottom: 4px;
text-align: left;
font: normal normal 600 14px/25px Open Sans;
letter-spacing: 0px;
color: #25334A;
opacity: 1;
}
.wrapper .passed {
}

.wrapper :before {
  font-family: "Open Sans";
  content: "⬤";
  color: #E4E5E8;
  display: inline-block;
  margin-right: 10px;
}


.wrapper .passed:before {
  content: "⬤";
  color: #2C97F4;
  display: inline-block;
  margin-right: 0.4em;
}
