@import "styles/globalSCSS/colors";
@import "styles/globalSCSS/typography";

.student {
  font-family: "IBM Plex Serif", serif;

  .container {
    max-width: 920px;
  }

  .green {
    color: $title-jungle-green;
  }

  .blue {
    color: $title-whale-blue;
  }

  .orange {
    color: $title-honeydrew-orange;
  }

  .grey {
    color: $paragraph-ghoul-grey;
  }

  .red {
    color: $error-tomato-red;
  }

  .title {
    font-size: 38px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 600;
  }

  .desc {
    font-size: 18px;
    font-weight: 600;
  }

  .screen {
    width: 100vw;
    height: max-content;
    min-height: 100vh;
  }

  .content {
    width: 920px;
    max-width: 920px;
  }

  .modal-overlay {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    z-index: 99999;

    .modal-cta {
      display: flex;
      justify-content: center;
    }
  }

  .white-panel {
    width: 100%;
    height: max-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000012;
    border: 0.0625rem solid #e3eaff;
    border-radius: 0.5625rem;
    padding: 2.5rem;
  }

  .input {
    margin-top: 0.75rem;
    width: 100%;
    height: 4.5rem;
    background: #ffffff;
    box-shadow: 0.1875rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.0392156863);
    border: 0.0625rem solid rgba(127, 147, 178, 0.1725490196);
    border-radius: 2.25rem;
    font: normal normal 600 1.25rem / 1.875rem IBM Plex Serif;
    color: #102243;
    padding: 1.5rem 2rem;

    &::placeholder {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: #d0d5e2;
    }
  }

  .input-filled {
    margin-top: 0.75rem;
    width: 100%;
    height: 4.5rem;
    background: #5f72941f;
    box-shadow: 0.1875rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.0392156863);
    border: 0.0625rem solid rgba(127, 147, 178, 0.1725490196);
    border-radius: 2.25rem;
    font: normal normal 600 1.25rem / 1.875rem IBM Plex Serif;
    color: #102243;
    padding: 1.5rem 2rem;

    &::placeholder {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: #d0d5e2;
    }
  }

  .input--error {
    margin-top: 0.75rem;
    height: 4.5rem;
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: #102243;
    padding: 1.5rem 2rem;
    background: #ffffff;
    box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
    border: 0.0625rem solid #ff5252;
    border-radius: 2.25rem;
  }
}

@media (max-width: 768px) {
  .student {
    .content {
      width: 100%;
      max-width: 90%;
    }

    .modal-overlay > * {
      width: 90%;
      height: max-content;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .student {
    .content {
      width: 100%;
      max-width: 90%;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .student {
    .content {
      width: 920px;
      max-width: 920px;
    }
  }
}

@media (min-width: 1200px) {
  .student {
    .content {
      width: 1100px;
      max-width: 1100px;
    }
  }
}
