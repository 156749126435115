@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.student-information-background-chat {
  position: absolute;
  top: 20px;
  right: -4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: transform 0.3s ease-in-out;

  &.icons-container-active {
    transform: translateX(-100%);
  }

  .icons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;

    .icon {
      width: 66px;
      height: 73px;
      cursor: pointer;
    }
  }
}

.chat-history-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 100;

  .chat-history-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;

    .chat-history-popout {
      font: normal normal 600 18px/26px IBM Plex Serif;
      background: #f9fafc;
      border-radius: 0;
      padding: 1rem;
      width: 348px;
      height: 100%;
      overflow-y: auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .icons-container-modal {
        position: fixed;
        right: 344px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        z-index: -1;

        .icon {
          width: 66px;
          height: 73px;
          cursor: pointer;
        }
      }

      .custom-chat-feed {
        display: flex;
        flex-direction: column;
        height: 100%;

        .custom-chat-feed-title {
          text-align: left;
          font: normal normal bold 22px/29px IBM Plex Serif;
          letter-spacing: 0px;
          color: #5f7294;

          .custom-chat-feed-divider {
            color: #adafb4;
          }
        }

        .custom-chat-feed-nochat {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          font: normal normal 600 20px/30px IBM Plex Serif;
          align-items: center;
          color: #5f7294;

          img {
            width: 8.5rem;
            height: auto;
          }
        }

        .chat-bubble-container {
          display: flex;
          width: 100%;
          margin: 5px 0;

          &.chat-bubble-left {
            justify-content: flex-start;
          }

          &.chat-bubble-right {
            justify-content: flex-end;
          }
        }

        .chat-bubble {
          padding: 10px;
          border-radius: 20px;
          max-width: 80%;

          &.chat-bubble-id-1 {
            background-color: #46c7c0;
            color: #f9fafc;
            text-align: left;
          }

          &.chat-bubble-id-0 {
            background-color: #edf1fd;
            color: #5f7294;
            text-align: left;
            letter-spacing: 0px;
          }
        }
      }

      .custom-chat-feed.nochat {
        justify-content: space-between;
      }
    }
  }
}
