@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-forgot-pin-rectangle-container {
  display: flex;
  position: absolute;

  .st-forgot-pin-rectangle,
  .ooops {
    background-position: center;
    width: 38.6875rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border: 0.0625rem solid #eaeaea;
    border-radius: 1.5rem;
    transition: height 0.3s ease;

    .st-forgot-pin-contents-form > *,
    .st-forgot-pin-contents-sentnewpin > *,
    .st-forgot-pin-contents-noparentsemail > * {
      width: 100%;
    }

    .st-forgot-pin-contents-form,
    .st-forgot-pin-contents-sentnewpin,
    .st-forgot-pin-contents-noparentsemail {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 4rem;

      .st-forgot-pin-title {
        font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
        color: $title-jungle-green;
      }

      .st-forgot-pin-subtitle {
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-jungle-green;
      }

      .st-forgot-pin-cta-set {
        display: flex;
        flex-direction: column;
        align-items: center;

        .st-forgot-pin-confirm-btn {
          margin-top: 2.1875rem;
          margin-bottom: 1rem;
          cursor: pointer;
        }

        .st-forgot-pin-link {
          text-align: center;
          text-decoration: underline;
          font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
          color: $title-whale-blue;
        }
      }
    }

    .st-forgot-pin-contents-form {
      .st-forgot-pin-title {
        margin-bottom: 2rem;
      }

      .st-forgot-pin-subtitle {
        margin-bottom: 2.5rem;
      }

      .st-forgot-pin-email-label {
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-forgot-pin-input-error-highlight {
        margin-top: 0.75rem;
        height: 4.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-whale-blue;
        padding: 1.5rem 2rem;
        background: #ffffff;
        box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
        border: 0.0625rem solid $error-tomato-red;
        border-radius: 2.25rem;
      }

      .st-forgot-pin-field-validation {
        margin-top: 0.5rem;
        font: normal normal 600 1.25rem/1.5rem IBM Plex Serif;
        color: $error-tomato-red;
      }

      .st-forgot-pin-cta-set {
        display: flex;
        flex-direction: column;
        align-items: center;

        .st-forgot-pin-confirm-btn {
          margin-top: 2.1875rem;
          width: 10.625rem;
          height: 4.6875rem;
          cursor: pointer;
        }

        .st-forgot-pin-link {
          text-align: center;
          text-decoration: underline;
          font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
          color: $title-whale-blue;
          margin-top: 1rem;
        }
      }
    }

    .st-forgot-pin-contents-sentnewpin {
      .st-sent-new-pin-mail-iconcontainer {
        display: flex;
        justify-content: center;

        .st-sent-new-pin-mail-icon {
          height: 7.5rem;
        }
      }

      .st-sent-new-pin-title {
        text-align: center;
        margin-top: 2rem;
        font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
        color: $title-jungle-green;
      }

      .st-sent-new-pin-subtitle {
        margin-top: 2rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
        text-align: left;

        .st-sent-new-pin-email {
          color: $title-whale-blue;
        }

        .st-sent-new-pin-link {
          margin-top: 1.5rem;
          text-decoration: underline;
          font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
          color: $title-whale-blue;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .st-forgot-pin-contents-noparentsemail {
      .forgot-pin-no-parent-email-mail-iconcontainer {
        display: flex;
        justify-content: center;

        .forgot-pin-no-parent-email-mail-icon {
          width: 7.5625rem;
          height: 7.5rem;
        }
      }

      .forgot-pin-no-parent-email-title {
        margin-top: 0.75rem;
        text-align: center;
        font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
        color: $title-honeydrew-orange;
      }

      .forgot-pin-no-parent-email-subtitle {
        margin-top: 1.5rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
        text-align: left;
      }

      .forgot-pin-no-parent-email-try-again-btn {
        margin-top: 0.75rem;
      }

      .forgot-pin-no-parent-email-try-again-btn:hover,
      .forgot-pin-no-parent-email-link:hover {
        cursor: pointer;
      }

      .forgot-pin-no-parent-email-link {
        margin-top: 0.75rem;
        text-align: center;
        text-decoration: underline;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-whale-blue;
      }
    }
  }

  .ooops {
    width: max-content;
  }
}
