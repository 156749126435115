.so-admin-delete-container {
  display: flex;

  .so-admin-delete-overlay {
    background-color: rgba(31, 31, 31, 0.59);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .so-admin-delete-main-container {
    margin-top: 3.75rem;
    margin-left: 18.4375rem;
    width: 49.375rem;
    height: 35.125rem;
    /* UI Properties */
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.125rem;
    opacity: 1;

    .so-admin-delete-close-icon {
      width: 3.4375rem;
      height: 3.4375rem;
      margin-left: 45.9375rem;
    }

    .so-admin-delete-label-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .so-admin-delete-title {
        margin-top: 1.5rem;
        font: normal normal bold 1.625rem/2.25rem Open Sans;
        letter-spacing: 0rem;
        color: #1365ac;
        opacity: 1;
        text-align: center;
      }

      .so-admin-delete-bin-icon {
        width: 7.1875rem;
        height: 8.1875rem;
        margin-top: 4rem;
      }

      .so-admin-delete-btn-subtitle {
        margin-top: 3rem;
        font: normal normal medium 1rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #526586;
        opacity: 1;
        text-align: center;
        margin-bottom: 3rem;
      }
    }

    .so-admin-delete-btn-container {
      display: flex;
      justify-content: center;

      .so-admin-delete-button-no {
        width: 11.75rem;
        height: 3.75rem;
        /* UI Properties */
        background: var(---ffffff) 0% 0% no-repeat padding-box;
        border: 0.0625rem solid var(---1365ac);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #1365ac;
        border-radius: 0.125rem;
        opacity: 1;

        font: normal normal 600 1.125rem/1.5rem Open Sans;
        letter-spacing: 0rem;
        color: #1365ac;
        opacity: 1;
      }

      .so-admin-delete-button-yes {
        width: 11.75rem;
        height: 3.75rem;
        /* UI Properties */
        background: var(---1365ac) 0% 0% no-repeat padding-box;
        border: 0.0625rem solid var(---1365ac);
        background: #1365ac 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #1365ac;
        border-radius: 0.125rem;
        opacity: 1;
        font: normal normal 600 1.125rem/1.5rem Open Sans;
        letter-spacing: 0rem;
        color: #ffffff;
        opacity: 1;
        margin-left: 1rem;
      }
    }
  }
}
