$space-s: 1rem;
$space-m: 2.5rem;
$space-l: 3rem;
$space-xl: 4rem;

// Margin Utilities
.m-0 {
  margin: 0;
}

.m-s {
  margin: $space-s;
}

.m-m {
  margin: $space-m;
}

.m-l {
  margin: $space-l;
}

.m-xl {
  margin: $space-xl;
}

// Padding Utilities
.p-0 {
  padding: 0;
}

.p-s {
  padding: $space-s;
}

.p-m {
  padding: $space-m;
}

.p-l {
  padding: $space-l;
}

.p-xl {
  padding: $space-xl;
}

// Margin X (Horizontal) Utilities
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-s {
  margin-left: $space-s;
  margin-right: $space-s;
}

.mx-m {
  margin-left: $space-m;
  margin-right: $space-m;
}

.mx-l {
  margin-left: $space-l;
  margin-right: $space-l;
}

.mx-xl {
  margin-left: $space-xl;
  margin-right: $space-xl;
}

// Margin Y (Vertical) Utilities
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-s {
  margin-top: $space-s;
  margin-bottom: $space-s;
}

.my-m {
  margin-top: $space-m;
  margin-bottom: $space-m;
}

.my-l {
  margin-top: $space-l;
  margin-bottom: $space-l;
}

.my-xl {
  margin-top: $space-xl;
  margin-bottom: $space-xl;
}

// Padding X (Horizontal) Utilities
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-s {
  padding-left: $space-s;
  padding-right: $space-s;
}

.px-m {
  padding-left: $space-m;
  padding-right: $space-m;
}

.px-l {
  padding-left: $space-l;
  padding-right: $space-l;
}

.px-xl {
  padding-left: $space-xl;
  padding-right: $space-xl;
}

// Padding Y (Vertical) Utilities
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-s {
  padding-top: $space-s;
  padding-bottom: $space-s;
}

.py-m {
  padding-top: $space-m;
  padding-bottom: $space-m;
}

.py-l {
  padding-top: $space-l;
  padding-bottom: $space-l;
}

.py-xl {
  padding-top: $space-xl;
  padding-bottom: $space-xl;
}
