@import "../../styles/globalSCSS/colors";
@import "../../styles/globalSCSS/typography";

.so-org-init-sidebar-container {
  width: 17.8125rem;
  height: 100%;
  min-height: 100vh;
  max-width: 100vh;
  background-color: #1365ac;
  box-shadow: 0.1875rem 0rem 0.375rem #0000004e;
  opacity: 1;

  .so-org-init-sidebar-name {
    padding-top: 2.125rem;
    padding-left: 3.0625rem;
    padding-right: 3.0625rem;
    font: $so-font-sidebar-name-label;
    color: $so-color-sidebar-name-label;
    letter-spacing: 0rem;
    opacity: 1;
    margin-bottom: 2.25rem;
  }

  .so-side-icon-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    color: #ffffff;
    text-align: center;

    .so-nav-container {
      display: flex;
      flex-direction: row;
    }

    .selected-rect {
      width: 0.25rem;
      height: 2rem;
      margin-right: 3.3125rem;
      background: #40daff 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    .no-selected-rect {
      margin-right: 3.3125rem;
    }

    .so-side-icon {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.5rem;
      color: #ffffff;
    }

    .so-side-icon-admin {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.5625rem;
      color: #ffffff;
    }

    .so-side-icon-selected {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.0938rem;
      color: #40daff;
    }

    .so-side-icon-nav {
      text-align: left;
      font: normal normal bold 1rem/1.5625rem Open Sans;
      letter-spacing: 0rem;
      opacity: 1;
    }

    .so-icon-text {
      overflow-wrap: break-word;
      margin-top: 0.3125rem;
      font: $so-font-sidebar-nav-item-label;
    }

    .so-icon-text-admin {
      margin-top: 0.5rem;
      font: $so-font-sidebar-nav-item-label;
    }

    .so-side-icon-text {
      text-align: left;
      font: $so-font-sidebar-nav-item-label;
      letter-spacing: 0rem;
      opacity: 1;
    }
  }

  .so-side-icon-container-settings {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    // margin-left: 3.3125rem;
    color: #ffffff;
    text-align: center;

    .so-nav-container {
      display: flex;
      flex-direction: row;
    }

    .selected-rect {
      width: 0.25rem;
      height: 2rem;
      margin-right: 3.3125rem;
      background: #40daff 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    .no-selected-rect {
      margin-right: 3.3125rem;
    }

    .so-side-icon-setting {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.4375rem;
      color: $so-color-sidebar-nav-item-label-not-selected;
    }

    .so-side-icon-logout {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.5rem;
      color: $so-color-sidebar-nav-item-label-not-selected;
    }

    .so-side-icon-nav-setting {
      text-align: left;
      font: $so-font-sidebar-nav-item-label;
      letter-spacing: 0rem;
      opacity: 1;
    }

    .so-icon-text-setting {
      margin-top: 0.25rem;
    }

    .so-icon-text-logout {
      margin-top: 0.3125rem;
    }
  }

  .so-side-icon-container-misc {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.75rem;
    margin-left: 3.3125rem;
    color: $so-color-sidebar-nav-item-label-not-selected;
    text-align: center;
    margin-top: 28rem;

    .so-side-icon-misc {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.0938rem;
    }
    .so-side-icon-text-misc {
      text-align: left;
      font: $so-font-sidebar-nav-item-label;
      letter-spacing: 0rem;

      opacity: 1;
    }
  }

  .so-side-icon-container-logout {
    display: flex;
    flex-direction: row;
    margin-left: 3.3125rem;
    color: $so-color-sidebar-nav-item-label-not-selected;
    text-align: center;
    position: relative;

    .so-side-icon-logout {
      margin-right: 1.5rem;
      width: 1.3125rem;
      height: 1.1875rem;
      margin-top: 0.0938rem;
    }
    .so-side-icon-text-logout {
      text-align: left;
      font: $so-font-sidebar-nav-item-label;
      letter-spacing: 0rem;
      opacity: 1;
    }
  }
}

.so-org-init-white-container {
  margin-top: 2rem;
  margin-left: 3rem;

  .so-org-init-title {
    font: normal normal bold 1.625rem/2.25rem Open Sans;
    letter-spacing: 0rem;
    color: #25334a;
    opacity: 1;
  }

  .so-org-white-rec-container {
    width: 61.5625rem;
    height: 29.3125rem;
    margin-top: 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.5rem #b4b4b45a;
    border: 0.0625rem solid #e4e5e884;
    border-radius: 0.125rem;
    opacity: 1;

    .so-building {
      width: 9.9375rem;
      height: 9.9375rem;
      margin-left: 25.875rem;
      margin-top: 3rem;
    }

    .so-crte-building-title-container {
      text-align: center;
      display: flex;
      flex-direction: row;
      font: normal normal bold 1.625rem/2.25rem Open Sans;
      letter-spacing: 0rem;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;

      .so-crte-building-title-black {
        font: normal normal bold 1.625rem/2.25rem Open Sans;
        letter-spacing: 0rem;
        color: #25334a;
        margin-right: 0.4375rem;
      }

      .so-crte-building-title-blue {
        font: normal normal bold 1.625rem/2.25rem Open Sans;
        letter-spacing: 0rem;
        color: #2c97f4;
      }
    }

    .so-crte-building-subtitle {
      text-align: left;
      font: normal normal medium 1rem/1.375rem Open Sans;
      letter-spacing: 0rem;
      color: #526586;
      opacity: 1;
      margin-top: 1.5rem;
      margin-left: 14.9375rem;
    }

    .so-crte-building-btn-ctn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2.5rem;

      .building-btn-single {
        width: 11.75rem;
        height: 3.75rem;
        /* UI Properties */
        background: var(---1365ac) 0% 0% no-repeat padding-box;
        border: 0.0625rem solid var(---1365ac);
        background: #1365ac 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #1365ac;
        border-radius: 0.125rem;
        opacity: 1;

        text-align: left;
        font: normal normal 600 1.125rem/1.5rem Open Sans;
        letter-spacing: 0rem;
        color: #ffffff;
        opacity: 1;
        padding-left: 2.3125rem;
        margin-right: 1rem;
      }

      .building-btn-multiple {
        width: 11.75rem;
        height: 3.75rem;
        /* UI Properties */
        background: var(---1365ac) 0% 0% no-repeat padding-box;
        border: 0.0625rem solid var(---1365ac);
        background: #1365ac 0% 0% no-repeat padding-box;
        border: 0.0625rem solid #1365ac;
        border-radius: 0.125rem;
        opacity: 1;

        text-align: left;
        font: normal normal 600 1.125rem/1.5rem Open Sans;
        letter-spacing: 0rem;
        color: #ffffff;
        opacity: 1;
        padding-left: 1.625rem;
      }
    }
  }
}
