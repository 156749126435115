@import "../../../src/styles/globalSCSS/colors";
@import "../../../src/styles/globalSCSS/typography";

.float-label {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font: $so-font-input-floating-label;
  letter-spacing: 0px;
  color: $so-color-input-floating-label;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 8px;
}

.label-float {
  font: $so-font-dropdown-floating-label;
  letter-spacing: 0px;
  color: $so-color-input-floating-label;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  padding-left: 12px;
  transition: 0.2s ease all;
  top: 1px;
  font-size: 12px;
}
