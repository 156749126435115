@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal-email {
  background: white;
  width: 42.125rem !important;
  height: 30.75rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem;

  .close-icon-circular {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .parent-email-verification-success-icon-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0;

    .email-verification-success-icon {
      width: 7.625rem;
      height: 7.5625rem;
      margin-bottom: 2rem;
    }

    .parent-email-verification-label {
      text-align: center;
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-jungle-green;
      opacity: 1;
    }

    .parent-email-verification-subtitle {
      text-align: left;
      font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
      letter-spacing: 0px;
      opacity: 1;
      color: $paragraph-ghoul-grey;
      padding-left: 2.75rem;
      padding-right: 2.75rem;
      padding-top: 2rem;

      .highlight {
        color: $title-whale-blue;
      }
    }
  }
}
