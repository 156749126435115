@import "../../../styles/globalSCSS/colors";

.st-info-instructions-overlay {
  .st-info-instructions-panel {
    background-color: white;
    padding: 1.25rem;
    border-radius: 0.625rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    display: block;
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #707070;
    z-index: 2;
    padding: 3rem 4rem;
    border-radius: 2rem;
  }

  .st-info-instructions-close-btn {
    position: absolute;
    z-index: 10;
    top: 0.625rem;
    right: 0.625rem;
    cursor: pointer;
  }

  .st-info-instructions-title {
    font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
    color: $title-jungle-green;
    padding-bottom: 0.75rem;
  }

  .st-info-instructions-icon-set {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
  }

  .st-info-instructions-icon {
    margin-right: 1rem;
  }

  .st-info-instructions-label-set {
    display: flex;
    flex-direction: column;
  }

  .st-info-instructions-icon-title {
    margin-top: 0.3125rem;
    font: normal normal bold 1.375rem/1.8125rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
  }

  .st-info-instructions-icon-subtitle {
    font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
  }

  .st-info-instructions-language-desc-container {
    margin-top: 2.5rem;
  }

  .st-info-instructions-language-desc {
    font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
  }

  .st-info-instructions-language-highlight {
    font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
    color: $title-jungle-green;
  }

  .st-info-instructions-lets-start-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}