@import "../../../styles/globalSCSS/colors";

.student-information-aibox {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;
  min-height: 6.563rem;
  overflow: none;
  flex-shrink: 0;
  background-color: #e6e7ed;

  .student-information-aiexchange {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 6.9rem;
    width: 20.2rem;
    border-radius: 1.875rem;
    margin-top: -2rem;
    background-image: url("../../../assets/chatControllerBg.svg");
    background-position-y: -0.1rem;
    background-position-x: center;
    padding: 1rem;
    background-repeat: no-repeat;

    .blink {
      animation: blink 2s infinite;
    }

    .student-information-aiexchange--repeaticon,
    .student-information-aiexchange--recordicon,
    .student-information-aiexchange--aiicon {
      display: flex;
      align-items: center;
      height: 5.375rem;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.35s all;
      z-index: 1;
      justify-content: center;

      img {
        transition: 0.35s all;
      }
    }

    .show {
      display: block;
    }

    .student-information-aiexchange--repeaticon,
    .student-information-aiexchange--recordicon {
      img {
        width: 4.125rem;
        height: auto;
      }
    }

    .student-information-aiexchange--aiicon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 8.55rem;
      height: 6.25rem;
      z-index: 2;

      .pulse {
        img {
          width: 3rem;
          height: auto;
        }
      }
    }
  }

  .pendingaires .student-information-aiexchange--aiicon {
    cursor: none;
    pointer-events: none;
  }

  .student-information-aiexchange.pendingaires {
    .student-information-aiexchange--aiicon {
      .pending {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        border-radius: 50%;
        transform: scale(1);
        background: transparent linear-gradient(180deg, #99c2f8 0%, #deecff 50%, #ffffff 100%) 0% 0% no-repeat padding-box;
        width: 5.438rem;
        height: 5.438rem;
        border-radius: 50%;
        border: 1px solid #E0ECFA;

      }

    }
  }

  .student-information-aiexchange:not(.pendingaires) .pulse {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.438rem;
    height: 5.438rem;
    border-radius: 50%;
    background: transparent linear-gradient(180deg, #99c2f8 0%, #deecff 50%, #ffffff 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #E0ECFA;
  }

  .student-information-aiexchange.pendingaires {
    justify-content: center;
    background: none;
    box-shadow: none;
    background: none;

    .left,
    .right {
      display: none;
    }


    .pulse img {
      width: 2.8rem;
    }

    .student-information-aiexchange--aiicon {
      width: 6.313rem;
      height: 6.313rem;
      cursor: none;
      background-color: #fff;
      box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 43%);
      pointer-events: none;

      .pulse {
        margin: 0 auto;
        margin-bottom: rem;
      }

      .loading {
        display: flex;
        flex-direction: row;
        gap: 0.469rem;
        margin: 0.5rem auto;


        .dot {
          display: inline-block;
          position: relative;
          border-radius: 50%;
          width: 0.419rem;
          height: 0.419rem;
          opacity: 0;
          background: #47b3f6;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;

          &.one {
            animation: loading 1.25s infinite;
            animation-delay: 0s;
          }

          &.two {
            animation: loading 1.25s infinite;
            animation-delay: 0.2s;
          }

          &.three {
            animation: loading 1.25s infinite;
            animation-delay: 0.4s;
          }

          &.four {
            animation: loading 1.25s infinite;
            animation-delay: 0.6s;
          }
        }
      }
    }
  }

  // .student-information-aiexchange.playingaiaudio .pulse {
  //   animation: pulse 1.5s infinite;
  // }

  .student-information-aiexchange.playingaiaudio .st-mic-test-recording-audio-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    max-width: 100%;
    margin-top: -2.8rem;

    .stoprecording-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -3.2rem;
      width: 5.375rem;
      height: 5.375rem;
      border-radius: 50%;
      background: #fff;
      z-index: 1;
      box-shadow: 0px 3px 6px #00000029;
      cursor: pointer;

      img {
        width: 4.125rem;
        height: auto;
        z-index: 3;
      }
    }
  }
}

.st-mic-test-recording-audio-container {
  display: flex;
  position: relative;
  align-items: center;
  top: -1rem;

  .stoprecording-icon {
    background: white;
    position: absolute;
    right: 0;
    border-radius: 100%;
    padding: 0.5rem;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
  }
}

/* */

.left,
.right {
  display: flex;
  justify-content: center;

  .student-information-aiexchange--repeaticon.active,
  .student-information-aiexchange--recordicon.active {
    animation: rotateEffect 3s ease-in-out;
    transform-origin: center;
  }

  .student-information-aiexchange--recordicon.active {
    animation-delay: 3s;
  }

  .instructions-repeat,
  .instructions-record,
  .instructions-mic,
  .instructions-send {
    display: none;
    position: absolute;
    background: white;
    border-radius: 4rem;
    font: normal normal 600 1.375rem/1.625rem IBM Plex Serif;
    width: max-content;
    color: #46c7c0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    opacity: 0;
    z-index: 2;

    .instruction-text-area {
      padding: 2rem;
    }

    .instruction-arrow-area {
      margin-top: -1rem;
      display: flex;
      width: 100%;
      position: absolute;
      justify-content: center;
    }

    .instruction-arrow-area div {
      position: absolute;
    }
  }

  .instructions-repeat,
  .instructions-record {
    bottom: 10rem;
  }

  .instructions-mic {
    animation: bouncediagonally 5s ease-in-out;
    bottom: 9rem;
    margin-left: 22rem;
    display: block;
    z-index: -5;
    opacity: 1;

    .instruction-text-area {
      width: 14.063rem;
      padding: 1.5rem;
    }

    .instruction-arrow-area {
      margin-top: -4rem;
      margin-left: -9rem;
    }
  }

  .instructions-repeat {
    animation: fadeInBottom 3s ease-in-out;
    margin-left: -5rem;
  }

  .instructions-record {
    animation: fadeInBottom 3s ease-in-out;
    animation-delay: 3s;
    margin-right: -5rem;
  }

  .instructions-send {
    animation: fadeInBottom 3s ease-in-out;
    margin-left: 7rem;
  }
}

@keyframes loading {
  0% {
    opacity: 0.75;
    transform: translateY(0) scale(1);
  }

  50% {
    opacity: 1;
    transform: translateY(-0.25rem) scale(1.75);
  }

  100% {
    opacity: 0.75;
    transform: translateY(0) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(83, 155, 252, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(83, 155, 252, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(83, 155, 252, 0);
  }
}

/*
@keyframes blink {
  0% {
    box-shadow: 0 0 0 0 rgba(83, 155, 252, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(83, 155, 252, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(83, 155, 252, 0);
  }
}*/

@keyframes rotateEffect {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  25%,
  75% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bouncediagonally {
  0% {
    transform: translate(10px, -10px);
    z-index: 10;
  }

  25% {
    transform: translate(0, 0);
    z-index: 10;
  }

  50% {
    transform: translate(10px, -10px);
    z-index: 10;
  }

  75% {
    transform: translate(0, 0);
    z-index: 10;
  }

  100% {
    transform: translate(10px, -10px);
    z-index: -5;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--repeaticon img,
  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--recordicon img {
    width: 5rem;
  }

  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--aiicon .pulse img {
    width: 3rem;
  }

  .student-information-aibox .student-information-aiexchange {
    background-position-y: -0.2rem;
    background-position-x: center;
    padding: 1rem;
    height: 8.5rem;
    margin-top: -3rem;
    width: 23.2rem;
  }

  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--aiicon {
    width: 10.55rem;
  }

  .student-information-aiexchange--instructions {
    top: -11rem;
  }

  .instruction-arrow-area div:nth-child(2) {
    right: 12.5rem;
  }

  .instruction-arrow-area div:nth-child(1) {
    left: 12.5rem;
  }

  .instructions-repeat,
  .instructions-record,
  .instructions-mic,
  .instructions-send {
    bottom: 13rem !important;
  }
}

@media screen and (max-width: 767px) {
  .student-information-aibox .student-information-aiexchange {
    height: 8.3rem;
    width: 22.7rem;
    margin-top: -3rem;
    background-position-y: -0.1rem;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 23rem;
  }

  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--repeaticon img,
  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--recordicon img {
    width: 5rem;
  }

  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--aiicon .pulse img {
    width: 3.688rem;
  }

  .student-information-aibox .student-information-aiexchange .student-information-aiexchange--aiicon {
    width: 10.23rem;
  }

  .student-information-aiexchange--instructions {
    width: 350px;
    top: -16rem;
  }

  .instruction-arrow-area div:nth-child(2) {
    right: 2.5rem;
  }

  .instruction-arrow-area div:nth-child(1) {
    left: 2.5rem;
  }

  .instructions-repeat,
  .instructions-record,
  .instructions-mic {
    bottom: 15rem !important;
  }
}


.blink .circle {
  display: flex;
}

.circle {
  border-radius: 50%;
  background-color: #5DA1FB;
  width: 130px;
  height: 130px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 4s infinite cubic-bezier(0.4, 0, 1, 1);
  position: absolute;
  z-index: -1;
  align-items: center;
  display: none;
}

@keyframes blink {
  0% {
    opacity: 0.15;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1.7s infinite;
}

@keyframes scaleIn {
  from {
    transform: scale(.35, .35);
    opacity: .85;
  }

  to {
    transform: scale(1, 1);
    opacity: 0;
  }
}