@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.modal-email {
  background: white;
  width: max-content;
  height: max-content;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .parent-email-verification-success-icon-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0;

    .email-verification-success-icon,
    .email-verification-failed-icon {
      width: 7.625rem;
      height: 7.5625rem;
      margin-bottom: 2rem;
    }

    .parent-email-verification-label {
      text-align: center;
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-jungle-green;
      opacity: 1;
    }

    .parent-email-verification-subtitle {
      text-align: left;
      font: normal normal 600 1.125rem/1.625rem IBM Plex Serif;
      letter-spacing: 0px;
      opacity: 1;
      color: $paragraph-ghoul-grey;
      padding-top: 2rem;

      .highlight {
        color: $title-whale-blue;
      }
    }

    .parent-email-verification-cta {
      margin-top: 1.25rem;
    }
  }
}

.orange {
  color: $title-honeydrew-orange !important;
}