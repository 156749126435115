@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";
@import "../../../styles/globalSCSS/margins";

.st-reset-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../../assets/studentLoginBackground.png);
  background-size: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
