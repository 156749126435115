@import "../../../styles/globalSCSS/colors";

.st-test-your-mic-overlay {
  background: #0000009f 0% 0% no-repeat padding-box;

  .st-test-your-mic-overlay-popup {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2.5rem;
    width: max-content;
    height: 6.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 7.75rem;
    padding: 2.125rem 3.375rem;

    .st-test-your-mic-overlay-popup-text {
      font: normal normal bold 1.5rem/2rem IBM Plex Serif;
      color: $title-jungle-green;
    }
  }
}

.st-test-your-mic-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000012;
  border: 0.0625rem solid #e3eaff;
  border-radius: 0.5625rem;
  padding: 2.5rem;
  margin: 0 auto;

  .st-test-your-mic-contents {
    .st-test-your-mic-icon {
      width: 5.125rem;
      height: 5.4375rem;
    }

    .st-test-your-mic-title {
      margin-top: 3.375rem;
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      color: $title-whale-blue;
    }

    .st-test-your-mic-subtitle {
      margin-top: 2.25rem;
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .st-test-your-mic-verify-btn {
      margin-top: 4.5rem;
    }
  }
}

@media (max-width: 768px) {
  .st-test-your-mic-overlay {
    .st-test-your-mic-overlay-popup {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
}