@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.student-dashboard-container {
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  .student-card-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.5rem;
  }
}
