@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-add-parents-email-contents {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000012;
  border: 0.0625rem solid #e3eaff;
  border-radius: 0.5625rem;
  padding: 3rem;

  .st-add-parents-email-title {
    font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
    color: $title-whale-blue;
  }

  .st-add-parents-email-subtitle {
    margin-top: 2rem;
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
  }

  .st-add-parents-email-input {
    margin-top: 0.75rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0.1875rem 0.1875rem 0.1875rem #0000000a;
    border: 0.0625rem solid #7f93b22c;
    border-radius: 2.25rem;
    width: 30.6875rem;
    height: 4.5rem;
    padding-left: 2rem;
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: $input-midnight-blue;
  }

  .st-add-parents-email-input::placeholder {
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: #d0d5e2;
  }

  .st-add-parents-email-error {
    margin-top: 0.75rem;
    color: red;
    font: normal normal 600 1rem/1.5rem IBM Plex Serif;
  }

  .st-add-parents-email-verify-btn-container {
    margin-top: 2rem;

    .st-add-parents-email-verify-btn {
      cursor: pointer;

      &__disabled {
        cursor: not-allowed;
      }
    }

    .disabled-verify-btn {
      width: 10.625rem;
      height: 4.6875rem;
    }

    .verify-btn {
      width: 10.625rem;
      height: 4.6875rem;
    }
  }
}

@media (max-width: 480px) {
  .st-add-parents-email-contents {
    .st-add-parents-email-input {
      width: 100%;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {}

@media (min-width: 769px) and (max-width: 1024px) {}

@media (min-width: 1025px) {}