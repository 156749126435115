@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.student-profile-container {
  .profile-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1rem auto;

    .profile-name-email {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 100%;

      .student-name,
      .student-email {
        font-family: IBM Plex Serif;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: $title-whale-blue;
        margin: 0;
      }

      .student-name {
        text-align: left;
        font-size: 2.375rem;
        font-weight: bold;
      }

      .student-email {
        text-decoration: none;
      }
    }

    .info-item-rectangle {
      background: #ffffff;
      box-shadow: 0px 0.1875rem 0.375rem #00000012;
      border: 1px solid #e3eaff;
      border-radius: 0.5625rem;
      width: 100%;
      height: max-content;
      padding: 3rem;
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;

      .info-profile-label-container {
        display: flex;
        flex-direction: column;

        .info-label,
        .info-value {
          font-family: IBM Plex Serif;
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 1.875rem;
        }

        .info-label {
          color: $paragraph-ghoul-grey;
        }

        .info-value {
          margin-top: 0.75rem;
          color: $title-jungle-green;
        }
      }
    }
  }
}

.info-item-rectangle img:hover {
  cursor: pointer;
}

@media (max-width: 480px) {
  .profile-name-email {
    flex-direction: column;
    align-items: baseline;
    gap: 1rem;
  }

  .info-item-rectangle {
    flex-direction: column;
    gap: 1.25rem;

    .studentbtn-cta {
      margin: 0 auto;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .profile-name-email {
    flex-direction: column;
    align-items: baseline;
    gap: 1rem;
  }

  .student-profile-container .profile-content {
    text-align: center;
  }

  .info-item-rectangle {
    flex-direction: column;
    gap: 1.25rem;

    .studentbtn-cta {
      margin: 0 auto;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .profile-name-email {
    flex-direction: column;
    align-items: baseline;
    gap: 1rem;
  }
}

@media (min-width: 1025px) {}