@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.student-information-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background: white;

  .student-information-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .student-information-background-img {
      display: block;
      width: 100%;
      height: calc(100% - 105px);
      object-fit: contain;
      background-color: #0d1721;
    }

    .student-information-chatbox {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 4.063rem;
      justify-content: center;
      background: #f9fafc;
      padding: 0 4.625rem;
      text-align: left;
      box-sizing: border-box;
      flex-grow: 1;
      position: relative;
      font: normal normal bold 22px/29px IBM Plex Serif;
      color: $paragraph-ghoul-grey;
      flex-shrink: 0;

      .floating {
        transition: all 0.5s ease-in-out;
        animation: bounce 1.5s infinite ease-in-out;
      }

      @keyframes bounce {

        0%,
        100% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(-5px);
        }
      }
    }
  }
}