@import "../../../styles/globalSCSS/colors";

.interrupt-modal {
  .interrupt-modal-container {
    background: white;
    padding: 4rem;
    border-radius: 2rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
    max-width: 46.313rem;
    position: relative;

    h1 {
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-honeydrew-orange;
      padding-bottom: 1rem;
      margin-top: 1rem;
    }

    p {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $paragraph-ghoul-grey;
    }

    .modal-cta {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 2.5rem;
    }

    .modal-cta img:hover,
    .interrupt-modal-close-btn:hover {
      cursor: pointer;
    }
  }
}
