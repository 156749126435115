@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.so-settings-container {
  display: flex;

  .so-settings-white-container {
    margin-top: 2rem;
    margin-left: 3rem;

    .so-settings-title {
      font: $so-font-settings-title;
      letter-spacing: 0rem;
      color: $so-color-family-title;
      opacity: 1;
      margin-top: 0.125rem;
      margin-bottom: 2rem;
    }

    .so-settings-form-inputs {
      width: 32.75rem;
      height: 25.1875rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0.125rem 0.125rem 0.375rem 0.375rem #0000000b;
      border-radius: 0.125rem;
      opacity: 1;
      .so-settings-info-container {
        padding: 2rem;
        .so-settings-form-title {
          font: $so-font-sidebar-name-label;
          letter-spacing: 0rem;
          color: $so-color-family-title;
          opacity: 1;
        }
        .so-settings-input {
          margin-top: 1rem;
          width: 28.75rem;
          height: 3.75rem;
          padding-left: 1.5rem;
          font: $so-font-input-floating;
          background: #f4f8fa 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e4e5e8;
          border-radius: 0.125rem;
          opacity: 1;
        }
        .so-settings-label {
          font: $so-font-dropdown-floating-label;
          letter-spacing: 0rem;
          color: $so-color-input-floating-label;
          opacity: 1;
          opacity: 1;
          position: absolute;
          pointer-events: none;
          left: 0.75rem;
          top: 0.0625rem;
          transition: 0.2s ease all;
          padding-left: 0.75rem;
          padding-top: 1.125rem;
          padding-bottom: 1.25rem;
        }
      }
    }

    .so-settings-change-password-container {
      margin-top: 2rem;
      width: 32.75rem;
      height: 5.75rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0.125rem 0.125rem 0.375rem 0.375rem #0000000b;
      border-radius: 0.125rem;
      opacity: 1;
      display: flex;
      flex-direction: row;

      .so-settings-change-password-title {
        text-align: left;
        font: $so-font-sidebar-name-label;
        letter-spacing: 0rem;
        color: $so-color-family-title;
        opacity: 1;
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 12.75rem;
      }

      .so-settings-change-password-icon {
        margin-top: 1rem;
        width: 4.75rem;
        height: 3.75rem;
      }
    }
  }
}
