@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.system-owner-reset-pw-container {
  font-family: "Open Sans";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .so-reset-pw-rectangle-container {
    display: contents;
    position: relative;
    .so-reset-pw-regex-container {
      position: absolute;
      margin-left: 44.5rem;
      top: 13.75rem;
      .so-dialog-regex {
        position: absolute;
      }
      .so-reset-regex {
        position: relative;
        margin-left: 0.75rem;
        margin-top: 1rem;
      }
    }

    .so-reset-pw-rectangle {
      position: absolute;
      background-position: center;
      /* Layout Properties */
      top: 5.25rem;
      width: 36.75rem;
      height: 30.75rem;
      /* UI Properties */
      background: var(---ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.1875rem 0.375rem #00000029;
      border: 0.0625rem solid #eaeaea;
      border-radius: 0.125rem;
      opacity: 1;

      .so-reset-pw-login-container {
        padding-top: 3.375rem;

        .so-reset-pw-login-title {
          font-size: 2.25rem;
          font-weight: bold;
          text-align: left;
          padding-left: 4rem;
          margin-bottom: 1.75rem;
        }

        .so-reset-pw-login-subtitle {
          text-align: left;
          font: normal normal 500 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #526586;
          opacity: 1;
          margin-left: 4rem;
          margin-bottom: 2rem;
        }

        .so-reset-pw-form-inputs {
          margin-left: 3.625rem;
          margin-right: 4rem;
        }

        .so-new-pw-input {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e4e5e8;
          opacity: 1;
          border-radius: 0.125rem;
          padding-left: 1.5rem;
          font: $so-font-input-floating;
          color: $so-color-input-floating;
        }
        .so-new-pw-label {
          font: $so-font-input-floating-label;
          color: $so-color-input-floating-label;
          padding-left: 1.5rem;
          margin-top: 0.125rem;
        }

        .so-confirm-pw-input {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e4e5e8;
          opacity: 1;
          border-radius: 0.125rem;
          padding-left: 1.5rem;
          font: $so-font-input-floating;
          color: $so-color-input-floating;
        }
        .so-confirm-pw-label {
          font: $so-font-input-floating-label;
          color: $so-color-input-floating-label;
          padding-left: 1.5rem;
          margin-top: 0.125rem;
        }
        .so-reset-field-validation {
          margin-left: 4rem;
          text-align: left;
          font: normal normal 600 1rem/1.375rem Open Sans;
          letter-spacing: 0rem;
          color: #fa3232;
          opacity: 1;
        }
        .so-reset-pw-btn-container {
          display: flex;
          justify-content: center;
          margin-top: 3.375rem;

          .so-reset-pw-btn-confirm {
            width: 11.75rem;
            height: 3.75rem;
            background: #1365ac 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #1365ac;
            border-radius: 0.125rem;
            opacity: 1;
            color: #ffffff;
            font-size: 1.125rem;
            font-weight: 600;
          }

          .so-reset-pw-btn-confirm-disabled {
            width: 11.75rem;
            height: 3.75rem;
            background: #1365ac 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #1365ac;
            border-radius: 0.125rem;
            opacity: 1;
            color: #ffffff;
            font-size: 1.125rem;
            font-weight: 600;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
