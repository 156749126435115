@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
}

.st-setup-screen {
  height: 100vh;
  min-height: max-content;
  overflow-y: hidden;
  background: #f9fafc 0% 0% no-repeat padding-box;

  .st-welcome-title {
    font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
    color: $title-jungle-green;
  }

  .st-welcome-subtitle {
    margin-top: 1.0625rem;
    font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
    color: $paragraph-ghoul-grey;
  }

  .st-welcome-blocked-microphone-rectangle-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000012;
    border: 0.0625rem solid #e3eaff;
    border-radius: 0.5625rem;
    padding: 3.5rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.75rem;

    .st-welcome-mail-icon {
      width: 3.9375rem;
      height: 3.9375rem;
    }

    // .st-welcome-icon-label-container {
    //   margin-left: -3rem !important;
    // }

    .st-welcome-mic-icon {
      width: 4.3125rem;
      height: 4.5625rem;
    }

    .st-welcome-label-container {
      display: flex;
      flex-direction: column;
      margin: 0 2.5rem;
      width: 39.375rem;
      pointer-events: none;

      .st-welcome-label-title {
        font: normal normal bold 1.5rem/2rem IBM Plex Serif;
        color: $title-jungle-green;
      }

      .st-welcome-label-subtitle {
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-welcome-try-again-email-subtitle {
        margin-top: 0.75rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-honeydrew-orange;
      }
    }

    .st-welcome-label-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .st-welcome-blocked-microphone-subtitle {
      margin-top: 1rem;
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      color: $title-honeydrew-orange;
      margin-right: 1rem;
    }

    .st-welcome-icon-label-container {
      letter-spacing: 0rem;
      color: $paragraph-ghoul-grey;
      display: flex;
      flex-direction: column;
      height: 2rem;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;

      .st-welcome-test-btn {
        margin-top: 2.875rem;
      }

      .st-welcome-success-label {
        text-align: left;
        font: normal normal bold 1.5rem/2rem IBM Plex Serif;
        color: $title-jungle-green;
      }
    }
  }

  .st-welcome-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000012;
    border: 0.0625rem solid #e3eaff;
    border-radius: 0.5625rem;
    padding: 3.5rem 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.75rem;

    .st-welcome-rectangle-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 3;
    }

    .st-welcome-mail-icon {
      width: 3.9375rem;
      height: 3.9375rem;
    }

    .st-welcome-mic-icon {
      width: 4.3125rem;
      height: 4.5625rem;
    }

    .st-welcome-label-container {
      display: flex;
      flex-direction: column;
      margin: 0 2.5rem;

      .st-welcome-label-title {
        font: normal normal bold 1.5rem/2rem IBM Plex Serif;
        color: $title-jungle-green;
      }

      .st-welcome-label-subtitle {
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $paragraph-ghoul-grey;
      }

      .st-welcome-blocked-microphone-subtitle {
        margin-top: 1.75rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-honeydrew-orange;
      }

      .st-welcome-try-again-email-subtitle {
        margin-top: 0.75rem;
        font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
        color: $title-honeydrew-orange;
      }
    }

    .st-welcome-icon-label-container {
      color: $paragraph-ghoul-grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 12.5rem;

      .st-welcome-success-label {
        text-align: left;
        font: normal normal bold 1.5rem/2rem IBM Plex Serif;
        color: $title-jungle-green;
      }
    }
  }

  .st-welcome-microphone-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.625rem;
  }
}

.st-welcome-icon-label-container.success img:hover {
  cursor: default !important;
}

.st-welcome-start-btn-container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 3.125rem;
}

@media (max-width: 480px) {
  .st-setup-screen {
    .st-welcome-wrapper {
      flex-direction: column;
      gap: 1.25rem;

      .st-welcome-rectangle-wrapper {
        flex-direction: column;
        flex: 1;
        gap: 1.25rem;

        .st-welcome-label-container {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .st-setup-screen {
    .st-welcome-wrapper {
      flex-direction: column;
      gap: 1.25rem;

      .st-welcome-rectangle-wrapper {
        gap: 2.5rem;

        .st-welcome-label-container {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
}

@media (min-width: 1025px) {
}
