@import "../../../styles/globalSCSS/colors";
@import "../../../styles/globalSCSS/typography";

.st-change-parents-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9fafc;

  .st-change-parents-email-rectangle-container {
    .st-change-parents-email-contents {
      .st-change-parents-email-title {
        font-family: IBM Plex Serif;
        font-weight: bold;
        font-size: 2.375rem;
        line-height: 3.0625rem;
        color: $title-whale-blue;
      }

      .st-change-parents-email-subtitle {
        margin-top: 1.5rem;
        font-family: IBM Plex Serif;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: $paragraph-ghoul-grey;
      }

      .st-change-parents-existing-email {
        margin-top: 0.75rem;
        max-width: 30.6875rem;
      }

      .st-change-parents-email-input {
        margin-top: 0.75rem;
        max-width: 30.6875rem;
      }

      .st-change-parents-email-error {
        margin-top: 0.75rem;
        color: red;
        font-family: IBM Plex Serif;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .st-change-parents-email-verify-btn-container {
        margin-top: 1.75rem;

        .disabled-verify-btn {
          width: 10.625rem;
          height: 4.6875rem;
        }

        .verify-btn {
          width: 10.625rem;
          height: 4.6875rem;
        }
      }
    }
  }
}