@import "../../../styles/globalSCSS/colors";

.micaccess-modal {
  .micaccess-modal-container {
    background: white;
    padding: 4rem;
    border-radius: 2rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
    max-width: 45.375rem;
    position: relative;

    .micaccess-modal-close-btn {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
    }

    h1 {
      font: normal normal bold 2.375rem/3.0625rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $title-honeydrew-orange;
      padding-bottom: 1rem;
      margin-top: 1rem;
    }

    span,
    p {
      font: normal normal 600 1.25rem/1.875rem IBM Plex Serif;
      letter-spacing: 0px;
      color: $paragraph-ghoul-grey;
    }

    img.no-btn {
      margin-right: 1rem;
    }

    .modal-cta img:hover,
    .micaccess-modal-close-btn:hover {
      cursor: pointer;
    }
  }

  .micaccess-modal-message {
    font: normal normal bold 1.5rem/2rem IBM Plex Serif;
    color: #333;
    margin-bottom: 4rem;
  }

  .continuebtn {
    width: 100%;
    text-decoration: underline;
    font: normal normal 600 1.25rem / 1.875rem IBM Plex Serif;
    color: $title-jungle-green;
    margin-top: 3rem;
  }
}